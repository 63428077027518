import React from 'react';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

const ErrorMessage = (props) => {
  return (
    <section className='error-page'>
      <SentimentVeryDissatisfiedIcon></SentimentVeryDissatisfiedIcon>
      <p>{props.detail ? props.detail : "Something went wrong!"}</p>
      {/* <p>You don’t have permission to view this asset. Please contact Xpansiv Operations</p> */}
      <a href={`/`}>Back home</a>
    </section>
  );
};

export default ErrorMessage;
