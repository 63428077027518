import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { TextField, MenuItem } from '@mui/material';
import { Loader } from 'semantic-ui-react';
import { Grid } from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, Tooltip } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { ToggleButtonGroup, ToggleButton, Pagination } from '@material-ui/lab/';
import SearchBar from 'material-ui-search-bar';

import ErrorMessage from '../../components/Error/Error';
import StripedTable from '../../components/Table/StripedTable.jsx';
import Arrow from '../../images/Arrow.svg';
import { useUserState, useUserDispatch, tabActive } from '../../context/UserContext';
import WorkflowStepsToPDF from '../../pdfs/WorkflowSteps.jsx';
import { listPayloads, listWorkflow, getNextStep } from '../../api/api';

import { DataCustodyRequestedStepModel } from '../../models/steps/DataCustodyRequestedStep';
import { DataCustodyReceivedStepModel } from '../../models/steps/DataCustodyReceivedStep';
import { ContextualizationRequestStepModel } from '../../models/steps/ContextualizationRequestStep';
import { ContextualizationStepModel } from '../../models/steps/ContextualizationStep';
import { IDGeneratorStepModel } from '../../models/steps/IDGeneratorRequestStep';
import { RegistrationStepModel } from '../../models/steps/RegistrationStep';
import { AssetRegistrationStepModel } from '../../models/steps/AssetRegistrationStep';
import { checkedOptions } from '../../constants/workflows';
import { readableStepName, escapeEmptyDetail } from '../../utils/utils';

//styling tooltip
const CustomTooltip = withStyles({
  tooltip: {
    fontSize: '12px',
    top: '-6px',
  },
})(Tooltip)

const Workflows = () => {
  const navigate = useNavigate();
  const user = useUserState();
  const params = useParams();
  const userDispatch = useUserDispatch();

  const myRef = useRef();
  const hiddenRef = useRef();

  const [searchPayloadId, setSearchPayloadId] = useState();
  const [searchIngestDateFrom, setSearchIngestDateFrom] = useState();
  const [searchIngestDateTo, setSearchIngestDateTo] = useState();
  const [searchLastUpdateFrom, setSearchLastUpdateFrom] = useState();
  const [searchLastUpdateTo, setSearchLastUpdateTo] = useState();
  const [incorrectIngestDate, setIncorrectIngestDate] = useState(false);
  const [incorrectLastUpdate, setIncorrectLastUpdate] = useState(false);
  const [invalidIngestDate, setInvalidIngestDate] = useState(false);
  const [invalidLastUpdate, setInvalidLastUpdate] = useState(false);
  const [statusSelected, setStatusSelected] = useState();
  const [payloadsList, setPayloadsList] = useState([]);
  const [initialPayloadsList, setInitialPayloadsList] = useState([]);
  const [stepSelected, setStepSelected] = useState();
  const [workflow, setWorkflow] = useState();
  const [payloadsCounter, setPayloadsCounter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState();
  const [errorDetail, setErrorDetail] = useState();
  const [hasError, setHasError] = useState(false);

  // Pagination
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = React.useState(Math.ceil(payloadsList?.length / itemsPerPage));

  useEffect(() => {
    if (window.location.pathname === `/workflows/${params.workflowId}`) {
      tabActive(userDispatch, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log('CONTEXT_API_UPDATED -> ', user);

    let riid = params.workflowId;

    if (user.workflowSelected || riid) {
      // getting workflow data to display steps details
      listWorkflow(
        user.workflowSelected ? user.workflowSelected : riid
      )
        .then((res) => {
          setWorkflow(res.data);
        })
        .catch((err) => {
          setHasError(true);
          setErrorStatus(err.response.status);
          setErrorDetail(err.response.data.detail);
        })

      // getting workflow data to display list all payloads
      listPayloads(
        user.workflowSelected ? user.workflowSelected : riid
      )
        .then((res) => {

        let filteredData = res.data.content.map(el => {
          if( el.status !== 'ACTIVE') {
            return {...el, isManual: false};
          }
          return el;
        })

        filteredData = filteredData.map(el => {
          if( el.status === 'ACTIVE') {
           getNextStep(el.payloadId)
            .then((ans) => {
              return {...el, isManual: ans.data.isManual};
            })
            .catch((err) => {
              console.log('Workflow does not have next step ->', err);
            })
          }
          return el;
        })

        setInitialPayloadsList(filteredData);
        setPayloadsList(filteredData);

        })
        .catch((err) => {
          setHasError(true);
          setErrorStatus(err.response.status);
          setErrorDetail(err.response.data.detail);
        })
        .finally(() => {
          finishLoader();
        })
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let arr = [];

    initialPayloadsList?.forEach((p) => {
      arr.push({ position: p.nextStepOrder - 1, count: 1 });
    })
    let positionsSum = Object.values(
      arr.reduce((acc, { position, count }) => {
        acc[position] = acc[position] || { position, count: 0 }
        acc[position].count += count
        return acc
      }, {})
    );

    // adding payload count key to obj export files
    workflow?.steps.forEach((s, index) => {
      positionsSum.forEach((p) => {
        if (index === p.position) {
          workflow.steps[index].payloadsAmount = p.count;
        }
      })

      if (!workflow?.steps[index].payloadsAmount) {
        workflow.steps[index].payloadsAmount = 0;
      }
    });

    setPayloadsCounter(positionsSum)
  }, [initialPayloadsList, workflow?.steps])

  // watching filters
  useEffect(() => {
    setIncorrectIngestDate(false);
    setIncorrectLastUpdate(false);
    setInvalidIngestDate(false);
    setInvalidLastUpdate(false);

    let ingestDateFrom = searchIngestDateFrom ? moment(searchIngestDateFrom).format('YYYY-MM-DD') : '';
    let ingestDateTo = searchIngestDateTo ? moment(searchIngestDateTo).format('YYYY-MM-DD') : '';
    let lastUpdateFrom = searchLastUpdateFrom ? moment(searchLastUpdateFrom).format('YYYY-MM-DD') : '';
    let lastUpdateTo = searchLastUpdateTo ? moment(searchLastUpdateTo).format('YYYY-MM-DD') : '';
    let today = moment().format('YYYY-MM-DD');

    if (ingestDateTo && ingestDateTo < ingestDateFrom) {
      setIncorrectIngestDate(true);
    }
    if (lastUpdateTo && lastUpdateTo < lastUpdateFrom) {
      setIncorrectLastUpdate(true);
    }
    if (ingestDateFrom > today) {
      setInvalidIngestDate(true);
    }
    if (lastUpdateFrom > today) {
      setInvalidLastUpdate(true);
    }

    const applyFilter = () => {
      let filteredRows = [];
      let initialRows = filteredRows.length ? filteredRows : initialPayloadsList;

      initialRows?.forEach((row) => {
        if (
          (!searchPayloadId ||
            row.payloadId.toLowerCase().includes(searchPayloadId)) &&
          ((!searchIngestDateFrom &&
            !searchIngestDateTo &&
            !searchLastUpdateFrom &&
            !searchLastUpdateTo) ||
            (row.createdAt?.split('T', 1)[0] >= ingestDateFrom &&
              row.createdAt?.split('T', 1)[0] <= ingestDateTo &&
              (lastUpdateFrom
                ? row.updatedAt?.split('T', 1)[0] >= lastUpdateFrom
                : true) &&
              (lastUpdateTo
                ? row.updatedAt?.split('T', 1)[0] <= lastUpdateTo
                : true)) ||
            (row.updatedAt?.split('T', 1)[0] >= lastUpdateFrom &&
              row.updatedAt?.split('T', 1)[0] <= lastUpdateTo &&
              (ingestDateFrom
                ? row.createdAt?.split('T', 1)[0] >= ingestDateFrom
                : true) &&
              (ingestDateTo
                ? row.createdAt?.split('T', 1)[0] <= ingestDateTo
                : true)) ||
            (ingestDateTo >= row.createdAt?.split('T', 1)[0] &&
              !ingestDateFrom &&
              !lastUpdateFrom &&
              !lastUpdateTo) ||
            (ingestDateFrom <= row.createdAt?.split('T', 1)[0] &&
              !ingestDateTo &&
              !lastUpdateFrom &&
              !lastUpdateTo) ||
            (lastUpdateTo >= row.updatedAt?.split('T', 1)[0] &&
              !ingestDateFrom &&
              !lastUpdateFrom &&
              !ingestDateTo) ||
            (lastUpdateFrom <= row.updatedAt?.split('T', 1)[0] &&
              !ingestDateFrom &&
              !lastUpdateTo &&
              !ingestDateTo)) &&
          (!statusSelected || row.status.toLowerCase() === statusSelected)
        ) {
          filteredRows.push(row);
        }
      })
      setPayloadsList(filteredRows);
    }
    applyFilter();
  }, [
    searchPayloadId,
    searchIngestDateFrom,
    searchIngestDateTo,
    statusSelected,
    initialPayloadsList,
    searchLastUpdateFrom,
    searchLastUpdateTo,
  ])

  // watching number of pages
  useEffect(() => {
    setNoOfPages(Math.ceil(payloadsList?.length / itemsPerPage));
  }, [itemsPerPage, payloadsList?.length])

  const handleItemsPerPage = (event, newAmount) => {
    setItemsPerPage(newAmount);
    if (newAmount === null) {
      return setItemsPerPage(5);
    }
  }

  const handleChangePage = (event, value) => {
    setPage(value);
  }

  const finishLoader = () => {
    setLoading(false);
  }

  // export payload list
  const PayloadsCSV = (e) => {
    e.preventDefault();
    let headers = ['Payload Id,Ingest Date ,Last Update , Status'];
    let payloadsCsv = payloadsList.reduce((acc, row) => {
      const { payloadId, createdAt, updatedAt, status } = row;

      let creationDate = createdAt?.split('T', 1)[0];
      let updateDate = updatedAt?.split('T', 1)[0];
      let creationTime = createdAt?.split('T', 2)[1].split('.', 1);
      let updateTime = updatedAt?.split('T', 2)[1].split('.', 1);

      acc.push([
        payloadId,
        creationDate + ' ' + creationTime + ' GMT',
        updateDate + ' ' + updateTime + ' GMT',
        status,
      ]);
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...payloadsCsv].join('\n'),
      fileName: workflow.title + ' Payloads',
      fileType: 'text/csv',
    });
  }

  // export workflow csv
  const WorkflowCSV = (e) => {
    e.preventDefault();

    let headers = ['Type, Title, Description, Order, Payloads Amount, New Custodian Party Name, New Custodian Party Id, Old Custodian Party Name, Old Custodian Party Id, Requesting Party Name, Receiving Party Id, Receiving Party Name, Receiving Party Id, Process Name, Process Version, Party Name, Party Id, Registry Name, Registry Id'];

    let payloadsCsv = workflow?.steps.reduce((acc, row) => {
      const {
        title,
        description,
        order,
        payloadsAmount,
        newCustodianPartyName,
        newCustodianPartyId,
        oldCustodianPartyName,
        oldCustodianPartyId,
        requestingPartyName,
        requestingPartyId,
        receivingPartyName,
        receivingPartyId,
        processName,
        processVersion,
        partyName,
        partyId,
        registryName,
        registryId,
      } = row;
      acc.push(
        [
          row['@type'],
          title,
          description,
          order,
          payloadsAmount,
          newCustodianPartyName,
          newCustodianPartyId,
          oldCustodianPartyName,
          oldCustodianPartyId,
          requestingPartyName,
          requestingPartyId,
          receivingPartyName,
          receivingPartyId,
          processName,
          processVersion,
          partyName,
          partyId,
          registryName,
          registryId,
        ].join(',')
      );
      return acc;
    }, [])

    downloadFile({
      data: [...headers, ...payloadsCsv].join('\n'),
      fileName: workflow.title,
      fileType: 'text/csv',
    });
  }

  // export workflow json
  const WFStepsToJson = (e) => {
    e.preventDefault();
    downloadFile({
      data: JSON.stringify(workflow?.steps),
      fileName: workflow.title + '.json',
      fileType: 'text/json',
    });
  }

  // export workflow pdf
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  }

  const selectEvent = (step) => {
    if (
      window.pageYOffset + window.innerHeight <= hiddenRef.current.offsetTop ||
      !stepSelected
    ) {
      myRef.current.scrollIntoView();
    }
    setStepSelected(step);
  }

  const payloadIdSelect = (payload) => {
    navigate(`/payloads/${payload.payloadId}`);
  }

  const payloadCounterIndex = (index) => {
    let payloadCount = 0;
    payloadsCounter?.forEach((s) => {
      if (index === s.position) {
        payloadCount = s.count;
      }
    });
    return payloadCount;
  }

  const renderFlowchart = (index) => {
    let stepsQty = workflow?.steps?.length;

    return (
      <div className='workflow-wrapper'>
        {workflow?.steps.map((step, index) => (
          <div className='workflow-step' key={index}>
            <div
              className={` step-wrapper ${
                (index + 1) % 5 === 0 && index !== 1 ? 'last' : ''
              }
              ${step.order === stepSelected?.order ? 'selected' : ''}
              `}
            >
              <CustomTooltip
                placement={'top'}
                arrow
                title={`Click to see ${ readableStepName(step['@type']) } details`}
              >
                <div
                  className='step'
                  onClick={() => {
                    selectEvent(step)
                  }}
                >
                  <p>
                    {
                      readableStepName(step['@type'])
                    }
                    {<span>({payloadCounterIndex(index)})</span>}
                  </p>
                </div>
              </CustomTooltip>
              <figure>
                <span
                  className={`${index + 1 < stepsQty ? 'arrow' : ''}`}
                ></span>
              </figure>
            </div>
            {(index + 1) % 5 === 0 && index !== 1 && index + 1 < stepsQty ? (
              <figure
                className={`${
                  (index + 1) % 5 === 0 && index !== 1 && index + 1 < stepsQty
                    ? 'arrow-long-wrapper'
                    : ''
                }`}
              >
                <span
                  className={`${
                    (index + 1) % 5 === 0 && index !== 1 ? 'arrow-long' : ''
                  }`}
                ></span>
              </figure>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
    );
  }

  const getStepObj = (step) => {
    switch (step['@type']) {
      case 'DataCustodyRequestedStep':
        return <>{DataCustodyRequestedStepModel(step)}</>;
      case 'DataCustodyReceivedStep':
        return <>{DataCustodyReceivedStepModel(step)}</>;
      case 'ContextualizationRequestStep':
        return <>{ContextualizationRequestStepModel(step)}</>;
      case 'ContextualizationStep':
        return <>{ContextualizationStepModel(step)}</>;
      case 'IDGeneratorStep':
        return <>{IDGeneratorStepModel(step)}</>;
      case 'RegistrationStep':
        return <>{RegistrationStepModel(step)}</>;
      case 'AssetRegistrationStep':
        return <>{AssetRegistrationStepModel(step)}</>;
      default:
        console.log('Step not set on the FE -> check Payloads.jsx file');
        break;
    }
  }

  return (
    <div className='payload'>
      {!workflow && !hasError ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <>
          {hasError ? (
            <ErrorMessage statusCode={errorStatus} detail={errorDetail}></ErrorMessage>
          ) : (
            <>
              <section className='search-result'>
                <p
                  className='link'
                  onClick={() => {
                    navigate('/')
                  }}
                >
                  Workflows
                </p>
                <p>
                  <img src={Arrow} alt='arrow'></img>
                </p>
                <p>{workflow.title}</p>
              </section>
              <Box
                sx={{
                  width: '100%',
                  border: '1px solid lightgrey',
                  borderRadius: '4px',
                  padding: '20px',
                  marginBottom: '20px',
                  display: 'block',
                }}
              >
                <p className='details-title'>
                  {escapeEmptyDetail(workflow.title)}
                </p>
                <p>
                  <span className='detail-title'>RIID: </span>
                  {escapeEmptyDetail(workflow.riid)}
                </p>
                <p>
                  <span className='detail-title'>Description: </span>
                  {escapeEmptyDetail(workflow.description)}
                </p>
                <section className='flowchart'>{renderFlowchart()}</section>
                <span ref={myRef}></span>
                <section className='export-btn-wrapper'>
                  <PDFDownloadLink
                    document={WorkflowStepsToPDF(workflow)}
                    fileName={escapeEmptyDetail(workflow.title)}
                  >
                    <Tooltip placement={'top'} arrow title='Export PDF'>
                      <button className='sm-btn'> pdf </button>
                    </Tooltip>
                  </PDFDownloadLink>
                  <Tooltip placement={'top'} arrow title='Export JSON'>
                    <button className='sm-btn json' onClick={WFStepsToJson}>
                      {'{ }'}
                    </button>
                  </Tooltip>
                  <Tooltip placement={'top'} arrow title='Export CSV'>
                    <button className='sm-btn' onClick={WorkflowCSV}>
                      csv
                    </button>
                  </Tooltip>
                </section>
              </Box>

              <span ref={hiddenRef}></span>

              {stepSelected ? 
                <Box
                  sx={{
                    width: '100%',
                    border: '1px solid lightgrey',
                    borderRadius: '4px',
                    padding: '20px',
                    marginBottom: '20px',
                    display: 'block',
                  }}>
                    {getStepObj(stepSelected)}
                </Box>
              : ''}

              <Box
                sx={{
                  width: '100%',
                  border: '1px solid lightgrey',
                  borderRadius: '4px',
                  padding: '20px',
                  marginBottom: '20px',
                  display: 'block',
                }}
              >
                <p className='details-title'>
                  <b>Payloads</b>
                </p>

                <div className='Home'>
                  <section className='filter-section'>
                    <SearchBar
                      placeholder={'Search by Payload Id'}
                      value={searchPayloadId}
                      onChange={(searchVal) =>
                        setSearchPayloadId(searchVal.toLowerCase())
                      }
                      onCancelSearch={() => setSearchPayloadId('')}
                    />
                    <div className='datepicker-wrapper'>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          format='DD-MM-YYYY'
                          label='Ingest date from'
                          value={
                            searchIngestDateFrom ? searchIngestDateFrom : ''
                          }
                          onChange={(newValue) => {
                            setSearchIngestDateFrom(newValue)
                          }}
                          renderInput={(params) => <TextField {...params} onKeyDown={(e) => e.preventDefault()} />}
                        />
                      </LocalizationProvider>
                      <p>
                        {invalidIngestDate
                          ? `Invalid date`
                          : incorrectIngestDate
                          ? `Invalid date range`
                          : ''}
                      </p>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        format='DD-MM-YYYY'
                        label='Ingest date to'
                        value={searchIngestDateTo ? searchIngestDateTo : ''}
                        onChange={(newValue) => {
                          setSearchIngestDateTo(newValue)
                        }}
                        renderInput={(params) => <TextField {...params} onKeyDown={(e) => e.preventDefault()} />}
                      />
                    </LocalizationProvider>
                    <div className='datepicker-wrapper'>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          format='DD-MM-YYYY'
                          label='Last update from'
                          value={
                            searchLastUpdateFrom ? searchLastUpdateFrom : ''
                          }
                          onChange={(newValue) => {
                            setSearchLastUpdateFrom(newValue)
                          }}
                          renderInput={(params) => <TextField {...params} onKeyDown={(e) => e.preventDefault()} />}
                        />
                      </LocalizationProvider>
                      <p>
                        {invalidLastUpdate
                          ? `Invalid date`
                          : incorrectLastUpdate
                          ? `Invalid date range`
                          : ''}
                      </p>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        format='DD-MM-YYYY'
                        label='Last update to'
                        value={searchLastUpdateTo ? searchLastUpdateTo : ''}
                        onChange={(newValue) => {
                          setSearchLastUpdateTo(newValue)
                        }}
                        renderInput={(params) => <TextField {...params} onKeyDown={(e) => e.preventDefault()} />}
                      />
                    </LocalizationProvider>
                    <TextField
                      select
                      label='Status'
                      placeholder='Status'
                      value={statusSelected}
                      onChange={(valueSelected) => {
                        setStatusSelected(valueSelected.target.value);
                      }}
                      >
                        <MenuItem key={"all"} value="">All</MenuItem>
                        {checkedOptions.map(el =>
                          <MenuItem key={el.key} value={el.value}>{el.text}</MenuItem>
                        )}
                    </TextField>
                  </section>
                  {!loading ? (
                    <>
                      <StripedTable
                        rowsClickable
                        headings={[
                          'Payload Id',
                          'Ingest Date',
                          'Last Update',
                          'Status',
                          'Manual Step'
                        ]}
                        rows={payloadsList
                          ?.slice(
                            (page - 1) * itemsPerPage,
                            page * itemsPerPage
                          )
                          .map((payload, index) => {
                            if (payloadsList.length) {
                              return {
                                elements: [
                                  <p>{payload.payloadId}</p>,
                                  <p>
                                    {moment(
                                      payload.createdAt.split('T', 1).toString()
                                    ).format('MM-DD-YYYY') +
                                      ' ' +
                                      payload.createdAt
                                        .split('T', 2)[1]
                                        .split('.', 1) +
                                      ' (GMT)'}
                                  </p>,
                                  <p>
                                    {moment(
                                      payload.updatedAt.split('T', 1).toString()
                                    ).format('MM-DD-YYYY') +
                                      ' ' +
                                      payload.updatedAt
                                        .split('T', 2)[1]
                                        .split('.', 1) +
                                      ' (GMT)'}
                                  </p>,
                                  <p
                                    className={` status ${
                                      payload.status === 'COMPLETED'
                                        ? 'completed'
                                        : payload.status === 'STOPPED'
                                        ? 'stopped'
                                        : payload.status === 'TRANSACTED'
                                        ? 'transacted'
                                        : payload.status === 'RETIRED'
                                        ? 'retired'
                                        : 'active'
                                    }`}
                                  >
                                    {payload.status}
                                  </p>,
                                  <p>{payload.status === 'ACTIVE' && payload.isManual === true ? 'PENDING MANUAL STEP' : '-'}</p>
                                ],
                                onClick: () => {
                                  payloadIdSelect(payload)
                                },
                              }
                            } else {
                              return {
                                elements: [],
                              }
                            }
                          })}
                      />
                      <Grid container spacing={2}>
                        <Grid item md={4}/>
                        <Grid item md={4}>
                          <Box component='div'>
                            <Pagination
                              count={noOfPages}
                              page={page}
                              onChange={handleChangePage}
                              defaultPage={0}
                              color='primary'
                              size='small'
                            />
                          </Box>
                        </Grid>
                        {payloadsList?.length > 0 &&
                          <Grid item md={4} p={2}>
                            <Box component='div' justifyContent={'right'} className='perpage'>
                              <Tooltip
                                placement={'right'}
                                arrow
                                title={<span>View per page</span>}
                              >
                                <ToggleButtonGroup
                                  value={itemsPerPage}
                                  exclusive
                                  onChange={handleItemsPerPage}
                                  aria-label='text alignment'
                                  size='small'
                                >
                                  <ToggleButton value={5} aria-label='left aligned'>
                                    5
                                  </ToggleButton>
                                  <ToggleButton value={50} aria-label='centered'>
                                    50
                                  </ToggleButton>
                                  <ToggleButton value={100} aria-label='justified'>
                                    100
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              </Tooltip>
                            </Box>
                          </Grid>
                        }
                      </Grid>
                      <section className='export-btn-wrapper'>
                        {payloadsList.length ? (
                          <Tooltip placement={'top'} arrow title='Export CSV'>
                            <button onClick={PayloadsCSV} className='sm-btn'>
                              csv
                            </button>
                          </Tooltip>
                        ) : (
                          ''
                        )}
                      </section>
                    </>
                  ) : (
                    <div className='empty-table'>
                      <Loader active indeterminate size='small' />
                    </div>
                  )}
                </div>
              </Box>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default Workflows
