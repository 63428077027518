import { Grid } from 'semantic-ui-react';
import { Box } from '@material-ui/core/';
import DecodedPayload from '../../components/DecodedPayload/DecodedPayload';
import { readableStepName, escapeEmptyDetail } from '../../utils/utils';

export const DataCustodyRequestedEventModel = (event, decodedPayload) => {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          border: '1px solid lightgrey',
          borderRadius: '4px',
          padding: '20px',
          marginBottom: '20px',
          display: 'block',
        }}>
        <p className='details-title'>
          <b>
            {
              readableStepName(event['@type'])
            }
          </b>
        </p>
        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={5}>
            <p className="detail-title">Title </p>
            <p className="detail-text">{escapeEmptyDetail(event.title)}</p>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={5}>
            <p className="detail-title">Description </p>
            <p className="detail-text">{escapeEmptyDetail(event.description)}</p>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={5}>
            <p className='detail-title'>Old Custodian Party Id </p>
            <p className='detail-text'>{escapeEmptyDetail(event.oldCustodianPartyId)}</p>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={5}>
            <p className='detail-title'>Old Custodian Party Name </p>
            <p className='detail-text'>{escapeEmptyDetail(event.oldCustodianPartyName)}</p>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={5}>
            <p className='detail-title'>New Custodian Party Id </p>
            <p className='detail-text'>{escapeEmptyDetail(event.newCustodianPartyId)}</p>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={5}>
            <p className='detail-title'>New Custodian Party Name </p>
            <p className='detail-text'>{escapeEmptyDetail(event.newCustodianPartyName)}</p>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={5}>
            <p className='detail-title'>Created at </p>
            <p className='detail-text'>
              {event.createdAt.split('T', 1) +
                ' ' +
                event.createdAt.split('T', 2)[1].split('.', 1)+' (GMT)'}
            </p>
          </Grid.Column>
        </Grid>
      </Box>
      <Box
        sx={{
          width: '100%',
          border: '1px solid lightgrey',
          borderRadius: '4px',
          padding: '20px',
          marginBottom: '20px',
          display: 'block',
        }}>
          { decodedPayload ?
            <DecodedPayload decodedPayload={decodedPayload} />
            :
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <p className='detail-title'>Payload </p>
                <p className='detail-text'>{escapeEmptyDetail(event.payload)}</p>
              </Grid.Column>
            </Grid>
          }
      </Box>
    </>
  );
};
