import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Formik, FieldArray } from 'formik';
import { Button, Loader, Modal } from 'semantic-ui-react';
import { InputLabel, FormControl, TextField, Select, MenuItem, Grid } from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Autocomplete from '@material-ui/lab/Autocomplete';

import ErrorMessage from '../../../components/Error/Error';
import { useUserDispatch, tabActive } from '../../../context/UserContext';
import { listProductionEntitiesForParty, listMasterCertificate, addMasterCertificate, editMasterCertificate, listLedgerParties } from '../../../api/api';
import { masterCertificateTypes, masterCertificateStatus, addValidationSchema, editValidationSchema } from '../../../constants/masterCertificates';

const AddMasterCertificate = (props) => {
  const userDispatch = useUserDispatch();

  const [loading, setLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState();
  const [errorDetail, setErrorDetail] = useState();
  const [hasError, setHasError] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState(null);
  const [productionEntities, setProductionEntities] = useState();
  const [ledgerParties, setLedgerParties] = useState();
  const [currentParty, setCurrentParty] = useState();
  const [certifyingBodyParty, setCertifyingBodyParty] = useState(null);
  const [certificateHolderParty, setCertificateHolderParty] = useState(null);

  useEffect(() => {
    if (window.location.pathname === `/master-certificates`) {
      tabActive(userDispatch, 2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    var current_party = JSON.parse(localStorage.getItem('current_party'));
    setCurrentParty(current_party);

    setLoading(true);

    listLedgerParties()
      .then((res) => {
        buildLedgerPartiesList(res.data.content);
        const parties = res.data.content;

        if (props.masterCertificateId !== undefined) {

          listMasterCertificate(props.masterCertificateId)
            .then((res) => {
              setIsEdit(true);

              listProductionEntitiesForParty(res.data.certifyingBodyPartyId, res.data.certificateHolderPartyId)
                .then((prods) => {
                  const prodEntityList = prods.data.content;
                  buildProductionEntityList(prodEntityList);

                  let boundingBoxAux = [];
                  if(res.data.boundingBox?.length > 0) {
                    boundingBoxAux = res.data.boundingBox.map(el => {
                      return {lat: el.split(',')[0], long: el.split(',')[1]}
                    });
                  }

                  let prodEntitiesAux = [];
                  if(res.data.productionEntities?.length > 0) {
                    prodEntitiesAux = res.data.productionEntities.filter(el => {
                      return prodEntityList.some(f => {
                        return (f.productionEntityId === el);
                      })
                    })
                  }
                  setCertifyingBodyParty(res.data.certifyingBodyPartyId);
                  setCertificateHolderParty(res.data.certificateHolderPartyId);
                  setFormData({
                    ...res.data,
                    certifyingBodyPartyID: res.data.certifyingBodyPartyId,
                    certifyingBodyPartyName: parties.find(el => el.party === current_party.id).partyName,
                    productionEntities: prodEntitiesAux,
                    boundingBox: boundingBoxAux,
                    activationDate: res.data.activationDate ? res.data.activationDate : '',
                    expirationDate: res.data.expirationDate ? res.data.expirationDate : ''
                  });
                })
              .catch((err) => {
                setHasError(true);
                setErrorStatus(err.response.status);
                setErrorDetail(err.response.data.detail);
              })
          }).catch((err) => {
            setHasError(true);
            setErrorStatus(err.response.status);
            setErrorDetail(err.response.data.detail);
          })
        } else {
          setCertifyingBodyParty(current_party.id);
          setFormData({
            externalCertificateId: '',
            certifyingBodyPartyId: current_party.id,
            certifyingBodyPartyName: parties.find(el => el.party === current_party.id).partyName,
            certificateHolderPartyId: '',
            certificateName: '',
            certificateType: '',
            certificateVersion: '',
            certificateRating: '',
            productionEntities: [],
            assessorName: '',
            boundingBox: [{lat: '', long: ''}],
            facilityId: '',
            status: '',
            activationDate: null,
            expirationDate: null,
            externalCertificateUrl: '',
            data: ''
          });
        }
      }).catch((err) => {
        setHasError(true);
        setErrorStatus(err.response.data.status);
        setErrorDetail(err.response.data.detail);
      })
    .finally(() => {
      setLoading(false);
    });   

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (certifyingBodyParty && certificateHolderParty) {
      getProductionEntities(certifyingBodyParty, certificateHolderParty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certifyingBodyParty, certificateHolderParty])

  const getProductionEntities = (certifyingBodyParty, certificateHolderParty) => {

    listProductionEntitiesForParty(certifyingBodyParty, certificateHolderParty)
      .then((res) => {
        buildProductionEntityList(res.data.content);
      }).catch((err) => {
        setHasError(true);
        setErrorStatus(err.response.data.status);
        setErrorDetail(err.response.data.detail);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  const buildProductionEntityList = (prods) => {
    const productionEntities = prods.map((el, index) => {
      return { key: index, text: el.name, value: el.productionEntityId};
    })
    setProductionEntities(productionEntities);
  }

  const buildLedgerPartiesList = (prods) => {
    const ledgerParties = prods.map((el, index) => {
      return { key: index, text: el.partyName, value: el.party};
    })
    setLedgerParties(ledgerParties);
  }

  const handleAddSubmit = (values, formikHelpers) => {

    const boundingBoxValues = [];
    values.boundingBox.forEach(el => {
      if(el.lat!== '' && el.long !== '')
        boundingBoxValues.push(`${el.lat},${el.long}`);
    })
    
    values = {
      ...values,
      activationDate: values.activationDate ? values.activationDate : null,
      expirationDate: values.expirationDate ? values.expirationDate : null,
      boundingBox: boundingBoxValues
    }

    if(currentParty && currentParty.roles.includes('RegistryOperator')) {
      values = {
        ...values,
      }
    } else {
      delete values.certifyingBodyPartyName;
      delete values.certifyingBodyPartyId;
    }
    
    addMasterCertificate(values)
    .then((res) => {
      props.onCloseSuccessfully();
    })
    .catch((err) => {
      if (err.response.data['error.key'] !== null) {
        formikHelpers.setErrors({[err.response.data['error.key']]: err.response.data.detail});
        formikHelpers.setSubmitting(false);
      } else if(err.response.data.fieldErrors !== null) {
        let errors = {};
        err.response.data.fieldErrors.forEach(el => {
          errors[el.field] = el.message;
        })
        formikHelpers.setErrors(errors);
        formikHelpers.setSubmitting(false);
      } else {
        setHasError(true)
        setErrorStatus(err.response.status)
        setErrorDetail(err.response.data.detail);
      }
    })
    .finally(() => {
      setLoading(false);
    });
  }

  const handleEditSubmit = (values, formikHelpers) => {
    
    const boundingBoxValues = [];
      values.boundingBox.forEach(el => {
        if(el.lat!== '' && el.long !== '')
          boundingBoxValues.push(`${el.lat},${el.long}`);
      })

    values = {
      externalCertificateId: values.externalCertificateId,
      certificateName: values.certificateName,
      certificateVersion: values.certificateVersion,
      certificateRating: values.certificateRating,
      productionEntities: values.productionEntities,
      assessorName: values.assessorName,
      facilityId: values.facilityId,
      status: values.status,
      activationDate: values.activationDate ? values.activationDate : null,
      expirationDate: values.expirationDate ? values.expirationDate : null,
      externalCertificateUrl: values.externalCertificateUrl,
      data: values.data,
      boundingBox: boundingBoxValues
    }

    editMasterCertificate(props.masterCertificateId, values)
    .then((res) => {
      props.onCloseSuccessfully();
    })
    .catch((err) => {
      if (err.response.data['error.key'] !== null) {
        formikHelpers.setErrors({[err.response.data['error.key']]: err.response.data.detail});
        formikHelpers.setSubmitting(false);
      } else if(err.response.data.fieldErrors !== null) {
        let errors = {};
        err.response.data.fieldErrors.forEach(el => {
          errors[el.field] = el.message;
        })
        formikHelpers.setErrors(errors);
        formikHelpers.setSubmitting(false);
      } else {
        setHasError(true)
        setErrorStatus(err.response.status)
        setErrorDetail(err.response.data.detail);
      }
    })
    .finally(() => {
      setLoading(false);
    });
  }

  return (
    <>
      {loading || !formData || !ledgerParties ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <>
          { hasError ? (
              <Modal
                open={props.open}
                onClose={props.onClose}
                className="formModal"
              >
                <Modal.Header>{props.masterCertificateId ? 'Edit' : 'Add'} Master Certificate</Modal.Header>
                <Modal.Content className="formModal-content">
                  <ErrorMessage statusCode={errorStatus} detail={errorDetail}></ErrorMessage>
                </Modal.Content>
                <Modal.Actions>
                    <Button type='cancel' onClick={props.onClose}>
                      Close
                    </Button>
                  </Modal.Actions>
              </Modal>
            ) : (
              <Formik
                initialValues={formData}
                validationSchema={isEdit? editValidationSchema : addValidationSchema}
                onSubmit={(isEdit) ? handleEditSubmit : handleAddSubmit}
              >
                  { formProps => ( 
                    <form onSubmit={formProps.handleSubmit} noValidate >
                      <Modal
                        open={props.open}
                        onClose={props.onClose}
                        className='formModal'
                      >
                        <Modal.Header>{props.masterCertificateId ? 'Edit' : 'Add'} Master Certificate</Modal.Header>
                        <Modal.Content className='formModal-content'>
                          <Grid container spacing={2}>
                          { isEdit ?
                            <>
                              <Grid item md={12}>
                                <TextField 
                                  fullWidth
                                  disabled
                                  label='Certifying Body Party'
                                  id='certifyingBodyPartyName'
                                  name='certifyingBodyPartyName'
                                  placeholder='Certifying Body Party'
                                  value={formProps.values.certifyingBodyPartyName ?? ''}
                                />
                              </Grid>
                              <Grid item md={12}>
                                <TextField 
                                  fullWidth
                                  disabled
                                  label='Certificate Holder Party'
                                  id='certificateHolderPartyName'
                                  name='certificateHolderPartyName'
                                  placeholder='Certificate Holder Party'
                                  value={formProps.values.certificateHolderPartyName ?? ''}
                                />
                              </Grid>
                              <Grid item md={12}>
                                <TextField 
                                  fullWidth
                                  disabled
                                  label='Certificate Type'
                                  id='certificateType'
                                  name='certificateType'
                                  placeholder='Certificate Type'
                                  value={formProps.values.certificateType ?? ''}
                                />
                              </Grid>
                            </>
                          :
                            <>
                              { currentParty && currentParty.roles.includes('RegistryOperator') &&
                                <Grid item md={12}>
                                  <Autocomplete
                                  id='certifyingBodyPartyId'
                                  fullwidth
                                  disableClearable
                                  options={ledgerParties}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      required
                                      label='Certifying Body Party'
                                      error={formProps.touched.certifyingBodyPartyId && Boolean(formProps.errors.certifyingBodyPartyId)}
                                      helperText={formProps.touched.certifyingBodyPartyId && formProps.errors.certifyingBodycertifyingBodyPartyIdParty}
                                    />
                                  )}
                                  getOptionLabel={(option) => option.text}
                                  value={ledgerParties.find(el => el.value === formProps.values.certifyingBodyPartyId)}
                                  onChange={
                                    (_event, newLedgerParty) => {
                                      formProps.handleChange({target: {value: newLedgerParty.value, name: 'certifyingBodyPartyId'}});
                                      formProps.handleChange({target: {value: newLedgerParty.text, name: 'certifyingBodyPartyName'}});
                                      setCertifyingBodyParty(newLedgerParty.value);
                                    }
                                  }
                                />
                                </Grid>
                              }
                              <Grid item md={12}>
                                <Autocomplete
                                  id='certificateHolderPartyId'
                                  fullwidth
                                  disableClearable
                                  options={ledgerParties}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      required
                                      label='Certificate Holder Party'
                                      error={formProps.touched.certificateHolderPartyId && Boolean(formProps.errors.certificateHolderPartyId)}
                                      helperText={formProps.touched.certificateHolderPartyId && formProps.errors.certificateHolderPartyId}
                                    />
                                  )}
                                  getOptionLabel={(option) => option.text}
                                  value={ledgerParties.find(el => el.value === formProps.values.certificateHolderPartyId)}
                                  onChange={
                                    (_event, newLedgerParty) => {
                                      formProps.handleChange({target: {value: newLedgerParty.value, name: 'certificateHolderPartyId'}});
                                      formProps.handleChange({target: {value: newLedgerParty.text, name: 'certificateHolderPartyName'}});
                                      setCertificateHolderParty(newLedgerParty.value);
                                    }
                                  }
                                />
                              </Grid>
                              <Grid item md={12}>
                                <TextField
                                  fullWidth
                                  select
                                  required
                                  label='Certificate Type'
                                  name='certificateType'
                                  placeholder='Certificate Type'
                                  value={formProps.values.certificateType ?? ''}
                                  onChange={formProps.handleChange}
                                  error={formProps.touched.certificateType && Boolean(formProps.errors.certificateType)}
                                  helperText={formProps.touched.certificateType && formProps.errors.certificateType}
                                  >
                                    {masterCertificateTypes.map(el =>
                                      <MenuItem key={el.key} value={el.value}>{el.text}</MenuItem>
                                    )}
                                </TextField>
                              </Grid>
                            </>
                          }
                          <Grid item md={12}>
                            <TextField 
                              fullWidth
                              label='Certificate Rating'
                              id='certificateRating'
                              name='certificateRating'
                              placeholder='Rating'
                              value={formProps.values.certificateRating ?? ''}
                              onChange={formProps.handleChange}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <TextField 
                              fullWidth
                              label='Certificate Version'
                              id='certificateVersion'
                              name='certificateVersion'
                              placeholder='Version'
                              value={formProps.values.certificateVersion ?? ''}
                              onChange={formProps.handleChange}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <TextField
                              required
                              fullWidth
                              label='Certificate Name'
                              id='certificateName'
                              name='certificateName'
                              placeholder='Name'
                              value={formProps.values.certificateName ?? ''}
                              onChange={formProps.handleChange}
                              error={formProps.touched.certificateName && Boolean(formProps.errors.certificateName)}
                              helperText={formProps.touched.certificateName && formProps.errors.certificateName}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <TextField 
                              fullWidth
                              label='External Certificate Id'
                              id='externalCertificateId'
                              name='externalCertificateId'
                              placeholder='External Certificate Id'
                              value={formProps.values.externalCertificateId ?? ''}
                              onChange={formProps.handleChange}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <FormControl fullWidth>
                              <InputLabel id='productionEntities-label'>Production Entities</InputLabel>
                              <Select
                                labelId='productionEntities-label'
                                id='productionEntities'
                                label='Production Entities'
                                multiple
                                disabled={!(productionEntities?.length > 0)}
                                name='productionEntities'
                                placeholder='Production Entities'
                                value={formProps.values.productionEntities ?? ''}
                                onChange={formProps.handleChange}
                                >
                                  {productionEntities?.map(el =>
                                    <MenuItem key={el.key} value={el.value}>{el.text}</MenuItem>
                                  )}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item md={12}>
                            <TextField 
                              fullWidth
                              label='Assessor Name'
                              id='assessorName'
                              name='assessorName'
                              placeholder='Assessor Name'
                              value={formProps.values.assessorName ?? ''}
                              onChange={formProps.handleChange}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <TextField 
                              fullWidth
                              label='Facility Id'
                              id='facilityId'
                              name='facilityId'
                              placeholder='Facility Id'
                              value={formProps.values.facilityId ?? ''}
                              onChange={formProps.handleChange}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <TextField
                              fullWidth
                              select
                              required
                              label='Status'
                              name='status'
                              placeholder='Status'
                              value={formProps.values.status ?? ''}
                              onChange={formProps.handleChange}
                              error={formProps.touched.status && Boolean(formProps.errors.status)}
                              helperText={formProps.touched.status && formProps.errors.status}
                              >
                                {masterCertificateStatus.map(el =>
                                  <MenuItem key={el.key} value={el.value}>{el.text}</MenuItem>
                                )}
                            </TextField>
                          </Grid>
                          <Grid item md={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                format='DD-MM-YYYY'
                                id='activationDate'
                                name='activationDate'
                                label='Activation Date'
                                value={formProps.values.activationDate ?? null}
                                onChange={(e) => formProps.handleChange({target: {value: moment(e).format('YYYY-MM-DD')+'T00:00:00Z', name: 'activationDate'}})}
                                renderInput={(params) => 
                                  <TextField {...params} error={Boolean(formProps.errors.activationDate)} helperText={formProps.errors.activationDate} />
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item md={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                format='DD-MM-YYYY'
                                id='expirationDate'
                                name='expirationDate'
                                label='Expiration Date'
                                value={formProps.values.expirationDate ?? null}
                                onChange={(e) => formProps.handleChange({target: {value: moment(e).format('YYYY-MM-DD')+'T00:00:00Z', name: 'expirationDate'}})}
                                renderInput={(params) => 
                                  <TextField {...params} error={Boolean(formProps.errors.expirationDate)} helperText={formProps.errors.expirationDate} />
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item md={12}>
                            <TextField 
                              fullWidth
                              label='External Certificate URL'
                              id='facilityId'
                              name='externalCertificateUrl'
                              placeholder='External Certificate URL'
                              value={formProps.values.externalCertificateUrl ?? ''}
                              onChange={formProps.handleChange}
                              error={Boolean(formProps.errors.externalCertificateUrl)}
                              helperText={formProps.errors.externalCertificateUrl}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <TextField 
                              fullWidth
                              label='Data'
                              id='data'
                              name='data'
                              placeholder='Data'
                              value={formProps.values.data ?? ''}
                              onChange={formProps.handleChange}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <p>Bounding Box</p>
                          </Grid>
                          <FieldArray
                            label='Bounding Box'
                            id='boundingBox'
                            name="boundingBox"
                            render={arrayHelpers => (
                              <>
                                {formProps.values.boundingBox?.map((bBox, index) => (
                                  <Grid container item md={12} spacing={2} display='contents' alignItems='flex-end' key={index}>
                                    <Grid item md={5}>
                                      <TextField 
                                        fullWidth
                                        label='Lat'
                                        id={`boundingBox[${index}].lat`}
                                        name={`boundingBox[${index}].lat`} 
                                        value={bBox.lat || ''}
                                        error={formProps.touched.boundingBox && formProps.errors.boundingBox !== undefined && Boolean(formProps.errors.boundingBox[index]?.lat)}
                                        helperText={formProps.touched.boundingBox && formProps.errors.boundingBox !== undefined && formProps.errors.boundingBox[index]?.lat}
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                      />
                                    </Grid>
                                    <Grid item md={5}>
                                      <TextField 
                                        fullWidth
                                        label='Long'
                                        id={`boundingBox[${index}].long`}
                                        name={`boundingBox.${index}.long`}
                                        value={bBox.long || ''}
                                        error={formProps.touched.boundingBox && formProps.errors.boundingBox !== undefined && Boolean(formProps.errors.boundingBox[index]?.long)}
                                        helperText={formProps.touched.boundingBox && formProps.errors.boundingBox !== undefined && formProps.errors.boundingBox[index]?.long}
                                        onChange={formProps.handleChange} 
                                        onBlur={formProps.handleBlur}
                                      />
                                    </Grid> 
                                    <Grid item md={2}>
                                      <div
                                        className='font-icon-wrapper'
                                        onClick={() => arrayHelpers.remove(index)}
                                      >
                                        <IconButton aria-label='delete'>
                                          <DeleteIcon />
                                        </IconButton>
                                      </div>
                                    </Grid>
                                  </Grid>
                                ))}

                                <Grid item md={12}>
                                  <Button onClick={() => arrayHelpers.push({ lat: '', long: '' })} className='actionButton'>
                                    Add more
                                  </Button>
                                </Grid>
                              </>
                            )}
                          />
                        </Grid>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button disabled={formProps.isSubmitting} type='cancel' onClick={props.onClose}>Cancel</Button>
                        <Button disabled={formProps.isSubmitting} type='submit' className='actionButton' onClick={formProps.handleSubmit}>{ isEdit ? 'Save' : 'Add' }</Button>
                      </Modal.Actions>
                    </Modal>
                  </form>
                  )
                } 
              </Formik> 
            )
          }
        </>
      )}
    </>
  )
}

export default AddMasterCertificate

