import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Loader, Modal, Button } from 'semantic-ui-react';
import { Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForward from '@mui/icons-material/ArrowForward';
import SearchBar from 'material-ui-search-bar';
import { Box, Tooltip } from '@material-ui/core/';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TextField, MenuItem } from '@mui/material';
import { ToggleButtonGroup, ToggleButton, Pagination } from '@material-ui/lab/';

import StripedTable from '../../components/Table/StripedTable.jsx';
import ErrorMessage from '../../components/Error/Error';
import { listMasterCertificates, deleteMasterCertificate } from '../../api/api';
import { useUserDispatch, tabActive } from '../../context/UserContext';
import { dateFormatter, escapeEmptyDetail } from '../../utils/utils.jsx';
import AddMasterCertificate from './Add/AddMasterCertificate.jsx';
import MasterCertificatesDetails from './Details/MasterCertificatesDetails.jsx';
import IssueMasterCertificate from './Issue/IssueMasterCertificate.jsx';

const MasterCertificates = () => {
  const userDispatch = useUserDispatch();

  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(true);
  const [, setInitialRows] = useState();
  const [errorStatus, setErrorStatus] = useState();
  const [errorDetail, setErrorDetail] = useState();
  const [hasError, setHasError] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [issueModalOpen, setIssueModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState();
  const [currentParty, setCurrentParty] = useState();

  // filter options
  const [typeArr, setTypeArr] = useState([]);
  const [statusArr, setStatusArr] = useState([]);
  // table filter
  const [searchedType, setSearchedType] = useState();
  const [searchActivationDate, setSearchActivationDate] = useState();
  const [searchExpirationDate, setSearchExpirationDate] = useState();
  const [searchFacilityId, setSearchFacilityId] = useState();
  const [searchCertificateHolderName, setSearchCertificateHolderName] = useState();
  // pagination
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState();

  useEffect(() => {
    if (window.location.pathname === '/master-certificates') {
      tabActive(userDispatch, 2);
    }

    var current_party = JSON.parse(localStorage.getItem('current_party'));
    setCurrentParty(current_party);

    getMasterCertificates();
    getFilters();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, page, searchedType, searchActivationDate, searchExpirationDate, searchFacilityId, searchCertificateHolderName]);

  const getFilters = () => {

    setLoading(true);
    
    listMasterCertificates(100000, '', '', '', '', '', '', '')
    .then((response) => {
      response.data.content.forEach((a, index) => {
        typeArr.push({
          key: index,
          value: String(a.certificateType),
          text: a.certificateType,
        });
        statusArr.push({
          key: index,
          value: String(a.status),
          text: a.status,
        });
      });

      const uniqueTypeObj = [
        ...new Map(typeArr.map((item) => [item.text, item])).values(),
      ];
      setTypeArr(uniqueTypeObj);

      const uniqueStatusArrObj = [
        ...new Map(statusArr.map((item) => [item.text, item])).values(),
      ];
      setStatusArr(uniqueStatusArrObj);

    })
    .catch((err) => {
      setHasError(true);
      setErrorStatus(err.response.status);
      setErrorDetail(err.response.data.detail);
    })
    .finally(() => {
      setLoading(false);
    });
}

  const getMasterCertificates = () => {

    setLoading(true);
    
    listMasterCertificates(
      itemsPerPage,
      page - 1,
      searchedType,
      searchActivationDate,
      searchExpirationDate,
      searchFacilityId,
      searchCertificateHolderName
    )
      .then((result) => {
        setInitialRows(result.data.content);
        setRows(result.data.content);
        setNoOfPages(result.data.totalPages);
      })
      .catch((err) => {
        setHasError(true);
        setErrorStatus(err.response.data.status);
        setErrorDetail(err.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage]);

  const handleItemsPerPage = (event, newAmount) => {
    setItemsPerPage(newAmount);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const deleteCertificate = () => {
    setLoading(true);

    deleteMasterCertificate(selectedCertificate)
    .then((res) => {
      setDeleteModalOpen(false);
      getMasterCertificates();
    })
    .catch((err) => {
      setHasError(true);
      setErrorStatus(err.response.status);
      setErrorDetail(err.response.data.detail);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  return (
    <>
      {loading || (rows?.length === undefined && !hasError) ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <>
          {hasError ? (
            <ErrorMessage statusCode={errorStatus} detail={errorDetail}></ErrorMessage>
          ) : (
            <div className='Home'>
              <section className='filter-section'>
                <TextField
                  select
                  label='Type'
                  placeholder='Type'
                  value={searchedType}
                  onChange={(valueSelected) => {
                    setSearchedType(valueSelected.target.value);
                  }}
                  >
                    <MenuItem key={"all"} value="">All</MenuItem>
                    {typeArr.map(el =>
                      <MenuItem key={el.key} value={el.value}>{el.text}</MenuItem>
                    )}
                </TextField>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    format='DD-MM-YYYY'
                    label='Activation Date'
                    value={
                      searchActivationDate ? searchActivationDate : ''
                    }
                    onChange={(newValue) => {
                      if(moment(moment(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid())
                        setSearchActivationDate(moment(newValue).format('YYYY-MM-DD'))
                        else
                          setSearchActivationDate(null)
                    }}
                    renderInput={(params) => <TextField {...params} onKeyDown={(e) => e.preventDefault()} />}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    format='DD-MM-YYYY'
                    label='Expiration Date'
                    value={
                      searchExpirationDate ? searchExpirationDate : ''
                    }
                    onChange={(newValue) => {
                      if(moment(moment(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid())
                        setSearchExpirationDate(moment(newValue).format('YYYY-MM-DD'))
                      else
                        setSearchExpirationDate(null)
                    }}
                    renderInput={(params) => <TextField {...params} onKeyDown={(e) => e.preventDefault()} />}
                  />
                </LocalizationProvider>
                <SearchBar
                  placeholder={'Facility Id'}
                  value={searchFacilityId}
                  onChange={(searchVal) =>
                    setSearchFacilityId(searchVal)
                  }
                  onCancelSearch={() => setSearchFacilityId('')}
                />
                <SearchBar
                  placeholder={'Certificate Holder Name'}
                  value={searchCertificateHolderName}
                  onChange={(searchVal) =>
                    setSearchCertificateHolderName(searchVal)
                  }
                  onCancelSearch={() => setSearchCertificateHolderName('')}
                />
              </section>
              <Grid container sx={{paddingRight: 2}}>
                <Grid item md={12}>
                  <section className='table-btn-wrapper'>
                    <Tooltip placement={'top'} arrow title='Add Master Certificate'>
                      <button onClick={() => setAddModalOpen(true)}> Add New</button>
                    </Tooltip>
                  </section>
                </Grid>
              </Grid>
              <section className='content'>
                {rows?.length !== undefined && !loading ? (
                  <>
                    <StripedTable
                      aria-label='simple table'
                      rowsClickable
                      headings={[
                        '',
                        '',
                        '',
                        '',
                        'Id',
                        'Name',
                        'Type',
                        'Activation Date',
                        'Expiration Date',
                        'Facility Id',
                        'Certificate Holder Name',
                        ''
                      ]}
                      rows={rows?.map((data, index) => {
                        if (data) {
                          return {
                            elements: [
                              <p index={index}></p>,
                              <p index={index}></p>,
                              <p index={index}></p>,
                              <p index={index}></p>,
                              <p>{escapeEmptyDetail(data.masterCertificateId)}</p>,
                              <p>{escapeEmptyDetail(data.certificateName)}</p>,
                              <p>{escapeEmptyDetail(data.certificateType )}</p>,
                              <p>{dateFormatter(data.activationDate)}</p>,
                              <p>{dateFormatter(data.expirationDate)}</p>,
                              <p>{escapeEmptyDetail(data.facilityId )}</p>,
                              <p>{escapeEmptyDetail(data.certificateHolderPartyName)}</p>,
                              <>
                                { (currentParty.id === data.certifyingBodyPartyId || currentParty.roles.includes('RegistryOperator')) &&
                                  <section className='export-btn-wrapper'>
                                    <Tooltip placement={'top'} arrow title='Issue Master Certificate'>
                                      <button className='sm-btn' onClick={(e) => {e.stopPropagation(); setSelectedCertificate(data.masterCertificateId); setIssueModalOpen(true)}}> <ArrowForward /> </button>
                                    </Tooltip>
                                    <Tooltip placement={'top'} arrow title='Edit Master Certificate'>
                                      <button className='sm-btn' onClick={(e) => {e.stopPropagation(); setSelectedCertificate(data.masterCertificateId); setEditModalOpen(true)}}> <EditIcon /> </button>
                                    </Tooltip>
                                    <Tooltip placement={'top'} arrow title='Delete Master Certificate'>
                                      <button className='sm-btn danger' onClick={(e) => {e.stopPropagation(); setSelectedCertificate(data.masterCertificateId); setDeleteModalOpen(true)}} > <DeleteIcon /> </button>
                                    </Tooltip>
                                  </section>
                                }
                              </>
                            ],
                            onClick: () => {
                              setSelectedCertificate(data.masterCertificateId);
                              setDetailModalOpen(true);
                            },
                          };
                        } else {
                          return {
                            elements: [],
                          };
                        }
                      })}
                    ></StripedTable>
                    <Grid container spacing={2}>
                      <Grid item md={4}/>
                      <Grid item md={4}>
                        <Box component='div'>
                          <Pagination
                            count={noOfPages}
                            page={page}
                            onChange={handleChangePage}
                            defaultPage={0}
                            color='primary'
                            size='small'
                          />
                        </Box>
                      </Grid>
                      {rows?.length > 0 &&
                        <Grid item md={4} p={2}>
                          <Box component='div' justifyContent={'right'} className='perpage'>
                            <Tooltip
                              placement={'right'}
                              arrow
                              title={<span>View per page</span>}
                            >
                              <ToggleButtonGroup
                                value={itemsPerPage}
                                exclusive
                                onChange={handleItemsPerPage}
                                aria-label='text alignment'
                                size='small'
                              >
                                <ToggleButton value={5} aria-label='left aligned'>
                                  5
                                </ToggleButton>
                                <ToggleButton value={50} aria-label='centered'>
                                  50
                                </ToggleButton>
                                <ToggleButton value={100} aria-label='justified'>
                                  100
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Tooltip>
                          </Box>
                        </Grid>
                      }
                    </Grid>
                    { detailModalOpen &&
                      <MasterCertificatesDetails 
                        open={detailModalOpen}
                        onClose={() => setDetailModalOpen(false)}
                        onCloseSuccessfully={() => {setDetailModalOpen(false); getMasterCertificates();}}
                        masterCertificateId={selectedCertificate}
                      />
                    }
                    { issueModalOpen &&
                      <IssueMasterCertificate
                        open={issueModalOpen}
                        onClose={() => setIssueModalOpen(false)}
                        onCloseSuccessfully={() => {setIssueModalOpen(false); getMasterCertificates();}}
                        masterCertificateId={selectedCertificate}
                      />
                    }
                    { addModalOpen &&
                      <AddMasterCertificate
                        open={addModalOpen}
                        onClose={() => setAddModalOpen(false)}
                        onCloseSuccessfully={() => {setAddModalOpen(false); getMasterCertificates(); getFilters();}}
                      />
                    }
                    { editModalOpen &&
                      <AddMasterCertificate 
                        open={editModalOpen}
                        onClose={() => setEditModalOpen(false)}
                        onCloseSuccessfully={() => {setEditModalOpen(false); getMasterCertificates(); getFilters();}}
                        masterCertificateId={selectedCertificate}
                      />
                    }
                    { deleteModalOpen &&
                      <Modal
                      open={deleteModalOpen}
                      onClose={() => setDeleteModalOpen(false)}
                      >
                        <Modal.Header>Delete Master Certificate</Modal.Header>
                        <Modal.Content>
                          Are you sure you want to delete Master Certificate: '{selectedCertificate}' ?
                        </Modal.Content>
                        <Modal.Actions>
                          <Button onClick={() => setDeleteModalOpen(false)}>
                            Cancel
                          </Button>
                          <Button negative onClick={deleteCertificate}>
                            Delete
                          </Button>
                        </Modal.Actions>
                      </Modal>
                    }
                  </>
                ) : (
                  <>
                    {rows?.length > 0 ? (
                      <div className='empty-table'>
                        <Loader active indeterminate size='small' />
                      </div>
                    ) : (
                      <StripedTable rows={[]}></StripedTable>
                    )}
                  </>
                )}
              </section>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MasterCertificates;