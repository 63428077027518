import React from 'react';
import { Grid } from 'semantic-ui-react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DecodedPayloadTable from './DecodedPayloadTable.jsx';
import DecodedPayloadObject from './DecodedPayloadObject.jsx';
import { escapeEmptyDetail } from '../../utils/utils';

const DecodedPayload = (props) => {

  const decodedPayload = props.decodedPayload;
  
  const payloadElements = () => {
    let elements = [];

      for (const [key, value] of Object.entries(decodedPayload)) {
        elements.push(
          <div key={key}>
            {value && Array.isArray(value) ?
              <>
                <Grid>
                  <Grid.Column mobile={16} tablet={8} computer={5}>
                    <p className='detail-title'>{key} </p>
                  </Grid.Column>
                </Grid>
                <DecodedPayloadTable payload={value} />
              </>
            :value && typeof value === 'object' ?
              <>
                { value !== null ?
                  <Grid>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <p className='detail-title'>{key} </p>
                      <DecodedPayloadObject payload={value} />
                    </Grid.Column>
                  </Grid>
                :
                  <Grid>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <p className='detail-title'>{key} </p>
                      <p className='detail-text'>{escapeEmptyDetail(value)}</p>
                    </Grid.Column>
                  </Grid>
                }
              </>
            : 
              <Grid>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <p className='detail-title'>{key} </p>
                  <p className='detail-text'>{escapeEmptyDetail(value)}</p>
                </Grid.Column>
              </Grid>
            }
          </div>
        )
      }
    return elements;
  }

  return (
    <>
      { decodedPayload &&
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{flexDirection: "row-reverse"}}>
            <p className='detail-title'>Payload</p>
          </AccordionSummary>
          <AccordionDetails >
            {payloadElements()}
          </AccordionDetails>
        </Accordion>
      }
    </>
  );
};

export default DecodedPayload