import React from 'react';

function DetailItem(props) {
  
    return (
        <div className="detail-item">
            <p className='detail-title'>{props.title}</p>
            <p className='detail-text'>{props.content}</p>
        </div>
  );
}

export default DetailItem;