import { escapeEmptyDetail } from '../../utils/utils';

const DecodedPayloadObject = (props) => {

  const decodedPayload = props.payload;
  
  const getElements = () => {
    let elements = [];
    
    for (const [key, value] of Object.entries(decodedPayload)) {
    if(key !== null) 
      elements.push(
        <div className="subitem" key={key}>
          <p className='detail-title'>{key} </p>
          <p className='detail-text'>{escapeEmptyDetail(value)}</p>
        </div>
      )
    }

    return elements;
  }

  return (
    <>
      {getElements()}
    </>
  );
};

export default DecodedPayloadObject