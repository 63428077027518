import React, { useEffect } from 'react';
import { DataGrid, GridActionsCellItem, GridCellModes } from '@mui/x-data-grid';
import { Button } from 'semantic-ui-react';
import { dateTimeFormatterReadable } from './../../utils/utils';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';

const DecodedPayloadEditableTable = (props) => {

  const columns = [
    { 
      field: 'name',
      headerName: 'Name',
      editable: true,
      sortable: false,
      flex: 1
    },
    {
      field: 'value',
      headerName: 'Value',
      editable: true,
      sortable: false,
      flex: 1
    },
    {
      field: 'dataType',
      headerName: 'Data Type',
      editable: true,
      sortable: false,
      flex: 1
    },
    {
      field: 'unitOfMeasure',
      headerName: 'Unit Of Measure',
      editable: true,
      sortable: false,
      flex: 1
    },
    {
      field: 'refinementDate',
      headerName: 'Refinement Date',
      editable: false,
      sortable: false,
      flex: 1
    },
    {
      field: 'dataRefiner',
      headerName: 'Data Refiner',
      editable: false,
      sortable: false,
      flex: 1
    },
    {
      field: 'actions',
      headerName: 'Actions',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: ({id}) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(id)}
            color="inherit"
          />,

          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => removeRow(id)}
            color="inherit"
          />,
        ];
      },
    }
  ];

  const attributes = props.attributes;
  const [rows, setRows] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });
  const [cellModesModel, setCellModesModel] = React.useState({});

  useEffect(() => {
    
    setRows(
      attributes.map((el, index) => {
        return {...el, id: index}
      }))
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const processRowUpdate = (newRow) => {
    let newRows = rows.map((row) => (row.id === newRow.id ? newRow : row))
    setRows(newRows)
    props.onChangeAttributes(newRows);
    return newRow;
  };

  const addRow = () => {
    var current_party = JSON.parse(localStorage.getItem('current_party'));
    const id = rows[rows.length-1].id+1;
    const newRows = [...rows, { id: id, name: '', value: '', dataType: '', unitOfMeasure: '', refinementDate: dateTimeFormatterReadable(new Date()), dataRefiner: current_party.id }]
    setRows(newRows);
    props.onChangeAttributes(newRows);
    setSelectedRow(newRows[newRows.length-1].id)
    setPaginationModel({
      pageSize: paginationModel.pageSize,
      page: Math.ceil(newRows.length/paginationModel.pageSize)-1,
    });
    setCellModesModel({
      ...cellModesModel,
      [id]: { ...cellModesModel[id], name: { mode: GridCellModes.Edit } },
    });
  }

  const removeRow = (id) => {
    let newRows = rows.filter(row => { return row.id !== id;})
    setRows(newRows);
    props.onChangeAttributes(newRows);
  }

  const handleEditClick = (id) => {
    setCellModesModel({
      ...cellModesModel,
      [id]: { ...cellModesModel[id], name: { mode: GridCellModes.Edit } },
    });
  }

  return (
    <>
      {rows &&
        <>
          <div style={{paddingTop: '10px', paddingBottom: '20px'}} >
            <DataGrid
              autoHeight
              disableColumnMenu
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setSelectedRow(newRowSelectionModel);
              }}
              cellModesModel={cellModesModel}
              onCellModesModelChange={(model) => setCellModesModel(model)}
              rowSelectionModel={selectedRow}
              columns={columns}
              rows={rows}
              processRowUpdate={processRowUpdate}
            />
          </div>
          <Button onClick={addRow} className='actionButton'>
            Add more
          </Button>
        </>
      }
    </>
  );
};

export default DecodedPayloadEditableTable;