import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';

import { Loader } from 'semantic-ui-react';
import { Box, Tooltip } from '@mui/material';
import ErrorMessage from '../../../components/Error/Error';
import Arrow from '../../../images/Arrow.svg';
import { useUserDispatch, tabActive } from '../../../context/UserContext';
import { listIssuedCertificate } from '../../../api/api';
import { dateFormatter, escapeEmptyDetail } from '../../../utils/utils';
import IssuedCertificateToPDF from '../../../pdfs/IssuedCertificate';
import DetailItem from '../../../components/DetailItem/DetailItem';

const IssuedCertificatesDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const userDispatch = useUserDispatch();

  const [loading, setLoading] = useState(true);
  const [issuedCertificate, setIssuedCertificate] = useState();
  const [errorStatus, setErrorStatus] = useState();
  const [errorDetail, setErrorDetail] = useState();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (window.location.pathname === `/issued-certificates/${params.id}`) {
      tabActive(userDispatch, 3)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let id = params.id;
    setLoading(true);

    if (id) {
      // getting workflow data to display steps details
      listIssuedCertificate(id)
        .then((res) => {
          setIssuedCertificate(res.data);
        })
        .catch((err) => {
          setHasError(true);
          setErrorStatus(err.response.status);
          setErrorDetail(err.response.data.detail);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='payload'>
      {loading || (!issuedCertificate && !hasError) ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <>
          {hasError ? (
            <ErrorMessage statusCode={errorStatus} detail={errorDetail}></ErrorMessage>
          ) : (
            <>
             <section className='search-result'>
                <p
                  className='link'
                  onClick={() => {
                    navigate('/issued-certificates')
                  }}
                >
                  Issued Certificates
                </p>
                <p>
                  <img src={Arrow} alt='arrow'></img>
                </p>
                <p>{issuedCertificate.certificateName}</p>
              </section>
              <Box
                sx={{
                  width: '100%',
                  border: '1px solid lightgrey',
                  borderRadius: '4px',
                  padding: '20px',
                  display: 'block',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    padding: '1rem 0',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)'
                  }}
                  >
                  <p className='details-title'>Issued Certificate Details: {issuedCertificate.certificateName}</p>
                  <section className='export-btn-wrapper'>
                    <PDFDownloadLink
                      document={IssuedCertificateToPDF(issuedCertificate)}
                      fileName={issuedCertificate.certificateName}
                    >
                      <Tooltip placement={'top'} arrow title='Export PDF'>
                        <button className='sm-btn'> pdf </button>
                      </Tooltip>
                    </PDFDownloadLink>
                  </section>
                </Box>
                <Box
                sx={{
                  width: '100%',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                }}
                >
                  <DetailItem title='Id' content={escapeEmptyDetail(issuedCertificate.issuedCertificateId)} />
                  <DetailItem title='Master Certificate Id' content={escapeEmptyDetail(issuedCertificate.masterCertificateId)} />
                  <DetailItem title='Certifying Body Party' content={escapeEmptyDetail(issuedCertificate.certifyingBodyPartyName)} />
                  <DetailItem title='Certificate Holder Party' content={escapeEmptyDetail(issuedCertificate.certificateHolderPartyName)} />
                  <DetailItem title='Certificate Name' content={escapeEmptyDetail(issuedCertificate.certificateName)} />
                  <DetailItem title='Certificate Type' content={escapeEmptyDetail(issuedCertificate.certificateType)} />
                  <DetailItem title='Certificate Rating' content={escapeEmptyDetail(issuedCertificate.certificateRating)} />
                  <DetailItem title='Certificate Version' content={escapeEmptyDetail(issuedCertificate.certificateVersion)} />
                  <DetailItem title='Certificate Name' content={escapeEmptyDetail(issuedCertificate.certificateName)} />
                  <DetailItem title='External Certificate Id' content={escapeEmptyDetail(issuedCertificate.externalCertificateId)} />
                  <DetailItem title='Production Entities' 
                    content={issuedCertificate.productionEntities?.length > 0 ?
                      issuedCertificate.productionEntities.map((el, index) => {
                      return <span key={index}>{el.name}{index+1 !== (issuedCertificate.productionEntities).length && ', '}</span>
                    })
                    : 
                      '-'
                  } />
                  <DetailItem title='Assessor Name' content={escapeEmptyDetail(issuedCertificate.assessorName)} />
                  <DetailItem title='Facility Id' content={escapeEmptyDetail(issuedCertificate.facilityId)} />
                  <DetailItem title='Status' content={escapeEmptyDetail(issuedCertificate.status)} />
                  <DetailItem title='Activation Date' content={dateFormatter(issuedCertificate.activationDate)} />
                  <DetailItem title='Expiration Date' content={dateFormatter(issuedCertificate.expirationDate)} />
                  <DetailItem title='External Certificate Url' content={escapeEmptyDetail(issuedCertificate.externalCertificateUrl)} />
                  <DetailItem title='Data' content={escapeEmptyDetail(issuedCertificate.data)} />
                  <DetailItem
                    title='Bounding Box'
                      content={issuedCertificate.boundingBox?.length > 0 ?
                        issuedCertificate.boundingBox.map((el, index) => {
                        return <span key={index}>{`'${el}'`}{index+1 !== (issuedCertificate.boundingBox).length && '; '}</span>
                      }) 
                      :
                      '-'
                    }
                  />
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default IssuedCertificatesDetails
