import { Grid } from "semantic-ui-react";
import { readableStepName, escapeEmptyDetail } from '../../utils/utils';

export const AssetRegistrationStepModel = (step) => {
  return (
    <>
      <p className='details-title'>
        <b>
          { readableStepName(step['@type']) }
        </b>
      </p>
        {/* <Grid className="subtitle">
          <Grid.Column>
            <p>Old Custodian Party</p>
          </Grid.Column>
        </Grid> */}
      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={5}>
          <p className="detail-title">Title </p>
          <p className="detail-text">{escapeEmptyDetail(step.title)}</p>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={5}>
          <p className="detail-title">Description </p>
          <p className="detail-text">{escapeEmptyDetail(step.description)}</p>
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={5}>
          <p className="detail-title">Old Custodian Party Id </p>
          <p className="detail-text">{escapeEmptyDetail(step.oldCustodianPartyId)}</p>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={5}>
          <p className="detail-title">Old Custodian Party Name </p>
          <p className="detail-text">{escapeEmptyDetail(step.oldCustodianPartyName)}</p>
        </Grid.Column>
      </Grid>
      {/* <Grid className="subtitle">
        <Grid.Column>
          <p>Registry</p>
        </Grid.Column>
      </Grid> */}
      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={5}>
          <p className="detail-title">Registry Account Id </p>
          <p className="detail-text">{escapeEmptyDetail(step.registryAccountId)}</p>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={5}>
          <p className="detail-title">Registry Account Name </p>
          <p className="detail-text">{escapeEmptyDetail(step.registryAccountName)}</p>
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={5}>
          <p className="detail-title">Registry </p>
          <p className="detail-text">{escapeEmptyDetail(step.registry)}</p>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={5}>
          <p className="detail-title">Registry Provider </p>
          <p className="detail-text">{escapeEmptyDetail(step.registryProvider)}</p>
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={5}>
          <p className="detail-title">Registry Environment </p>
          <p className="detail-text">{escapeEmptyDetail(step.registryEnvironment)}</p>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={5}>
          <p className="detail-title">Registry API Version </p>
          <p className="detail-text">{escapeEmptyDetail(step.registryAPIVersion)}</p>
        </Grid.Column>
      </Grid>
    </>
  );
};
