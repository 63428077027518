import React, { useState, useEffect } from 'react';
import { Button, Grid, Loader, Modal } from 'semantic-ui-react';

import { useUserDispatch, tabActive } from '../../../context/UserContext';
import { listProductionEntity } from '../../../api/api';
import { escapeEmptyDetail } from '../../../utils/utils';
import DetailItem from '../../../components/DetailItem/DetailItem';
import ErrorMessage from '../../../components/Error/Error';

const ProductionEntitiesDetails = (props) => {
  const userDispatch = useUserDispatch();

  const [productionEntity, setProductionEntity] = useState();
  const [loading, setLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState();
  const [errorDetail, setErrorDetail] = useState();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (window.location.pathname === `/production-entities`) {
      tabActive(userDispatch, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let id = props.productionEntityId;
    setLoading(true);

    if (id) {
      listProductionEntity(id)
        .then((res) => {
          setProductionEntity(res.data);
        })
        .catch((err) => {
          setHasError(true);
          setErrorStatus(err.response.status);
          setErrorDetail(err.response.data.detail);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='payload'>
      {loading || (!productionEntity && !hasError) ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <>
          { hasError ? (
            <Modal
              open={props.open}
              onClose={props.onClose}
              className="formModal"
            >
              <Modal.Header>Production Entity Details</Modal.Header>
              <Modal.Content className="formModal-content">
                <ErrorMessage statusCode={errorStatus} detail={errorDetail}></ErrorMessage>
              </Modal.Content>
              <Modal.Actions>
                  <Button type='cancel' onClick={props.onClose}>
                    Close
                  </Button>
                </Modal.Actions>
            </Modal>
            ) : (
              <Modal
                open={props.open}
                onClose={props.onClose}
                className="formModal"
              >
                <Modal.Header>Production Entity Details</Modal.Header>
                <Modal.Content className="formModal-content">
                  <Grid container spacing={2}>
                    <Grid md={12} className="full-row">
                      <DetailItem title='Id' content={escapeEmptyDetail(productionEntity.productionEntityId)} />
                    </Grid>
                    <Grid md={12} className='full-row'>
                      <DetailItem title='Certifying Body Party' content={escapeEmptyDetail(productionEntity.certifyingBodyPartyName)} />
                    </Grid>
                    <Grid md={12} className='full-row'>
                      <DetailItem title='Certificate Holder Party' content={escapeEmptyDetail(productionEntity.certificateHolderPartyName)} />
                    </Grid>
                    <Grid md={12} className="full-row">
                      <DetailItem title='External Id' content={escapeEmptyDetail(productionEntity.externalId)} />
                    </Grid>
                    <Grid md={12} className="full-row">
                      <DetailItem title='Production Entity Name' content={escapeEmptyDetail(productionEntity.name)} />
                    </Grid>
                    <Grid md={12} className="full-row">
                      <DetailItem title='Production Entity Type' content={escapeEmptyDetail(productionEntity.productionEntityType)} />
                    </Grid>
                    <Grid md={12} className="full-row">
                      <DetailItem title='Location' content={escapeEmptyDetail(productionEntity.location)} />
                    </Grid>
                    <Grid md={12} className="full-row">
                      <DetailItem title='Lat Long' content={escapeEmptyDetail(productionEntity.latlong)} />
                    </Grid>
                    <Grid md={12} className="full-row">
                      <DetailItem title='Volume Unit' content={escapeEmptyDetail(productionEntity.volumeUnit)} />
                    </Grid>
                    <Grid md={12} className="full-row">
                      <DetailItem title='Volume' content={escapeEmptyDetail(productionEntity.volume)} />
                    </Grid>
                    <Grid md={12} className='full-row'>
                      <DetailItem
                        title='Supplemental Data'
                        content={productionEntity.supplementalData?.length > 0 ?
                          productionEntity.supplementalData.map((el, index) => {
                            return (
                              <Grid.Column mobile={16} tablet={8} computer={5}>
                                <p className='detail-text'>{escapeEmptyDetail(el.name)}: {escapeEmptyDetail(el.value)}</p>
                              </Grid.Column>
                            )
                          }) 
                          :
                          '-'
                        }
                      />
                    </Grid>
                  </Grid>
                </Modal.Content>
                <Modal.Actions>
                  <Button type='cancel' onClick={props.onClose}>
                    Close
                  </Button>
                </Modal.Actions>
              </Modal>
            )
          }
        </>
      )}
    </div>
  )
}

export default ProductionEntitiesDetails
