import React from 'react';
import { Box } from '@material-ui/core/';
import { Grid } from 'semantic-ui-react';
import DecodedPayload from '../../components/DecodedPayload/DecodedPayload';
import { readableStepName } from '../../utils/utils';
import { escapeEmptyDetail } from '../../utils/utils';

const ContextualizationEventModel = (event, decodedPayload) => {
  let startingValues = [];

  event.startingValues.forEach((a, index) => {
    let nameFormatted = a.name.replaceAll('_', ' ');
    startingValues.push(
      <Grid.Column mobile={16} tablet={8} computer={5} key={index}>
        <p className='detail-title'>{nameFormatted}</p>
        <p className='detail-text'>
          {a.value < 1 ? Math.round(a.value * 100) + '%' : a.value}
        </p>
      </Grid.Column>
    );
  });

  let supportingInformation = [];

  event.supportingInformation.forEach((a, index) => {
    let nameFormatted = a.name.replaceAll('_', ' ');
    supportingInformation.push(
      <Grid.Column mobile={16} tablet={8} computer={5} key={index}>
        <p className='detail-title'>{nameFormatted}</p>
        <p className='detail-text'>
          {a.value < 1 ? Math.round(a.value * 100) + '%' : a.value}
        </p>
      </Grid.Column>
    );
  });

  return (
    <>
      <Box
        sx={{
          width: '100%',
          border: '1px solid lightgrey',
          borderRadius: '4px',
          padding: '20px',
          marginBottom: '20px',
          display: 'block',
      }}>
        <p className='details-title'>
          <b>
            { readableStepName(event['@type']) }
          </b>
        </p>
        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={5}>
            <p className="detail-title">Title </p>
            <p className="detail-text">{escapeEmptyDetail(event.title)}</p>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={5}>
            <p className="detail-title">Description </p>
            <p className="detail-text">{escapeEmptyDetail(event.description)}</p>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={5}>
            <p className='detail-title'>Party Id </p>
            <p className='detail-text'>{escapeEmptyDetail(event.partyId)}</p>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={5}>
            <p className='detail-title'>Party Name </p>
            <p className='detail-text'>{escapeEmptyDetail(event.partyName)}</p>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={5}>
            <p className='detail-title'>Process Name </p>
            <p className='detail-text'>{escapeEmptyDetail(event.processName)}</p>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={5}>
            <p className='detail-title'>Process Version </p>
            <p className='detail-text'>{escapeEmptyDetail(event.processVersion)}</p>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={5}>
            <p className='detail-title'>Service Name </p>
            <p className='detail-text'>{escapeEmptyDetail(event.serviceName)}</p>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={5}>
            <p className='detail-title'>Created at </p>
            <p className='detail-text'>
              {event.createdAt.split('T', 1) +
                ' ' +
                event.createdAt.split('T', 2)[1].split('.', 1)+' (GMT)'}
            </p>
          </Grid.Column>
        </Grid>
        <p className='detail-subtitle'>Starting Values </p>
        {event.startingValues.map((el, index) => {
          return <Grid key={index}>
            <Grid.Column mobile={16} tablet={8} computer={5}>
              <p className='detail-title'>Name </p>
              <p className='detail-text'>{escapeEmptyDetail(el.name)}</p>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={5}>
              <p className='detail-title'>Value</p>
              <p className='detail-text'>{escapeEmptyDetail(el.value)}</p>
            </Grid.Column>
          </Grid>
        })}
        <p className='detail-subtitle'>Result </p>
        {event.result.map((el, index) => {
          return <Grid key={index}>
            <Grid.Column mobile={16} tablet={8} computer={5}>
              <p className='detail-title'>Name </p>
              <p className='detail-text'>{escapeEmptyDetail(el.name)}</p>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={5}>
              <p className='detail-title'>Value</p>
              <p className='detail-text'>{escapeEmptyDetail(el.value)}</p>
            </Grid.Column>
          </Grid>
        })}
        <p className='detail-subtitle'>Supporting Information </p>
        {event.supportingInformation.map((el, index) => {
          return <Grid key={index}>
            <Grid.Column mobile={16} tablet={8} computer={5}>
              <p className='detail-title'>Name </p>
              <p className='detail-text'>{escapeEmptyDetail(el.name)}</p>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={5}>
              <p className='detail-title'>Value</p>
              <p className='detail-text'>{escapeEmptyDetail(el.value)}</p>
            </Grid.Column>
          </Grid>
        })}
      </Box>
      <Box
        sx={{
          width: '100%',
          border: '1px solid lightgrey',
          borderRadius: '4px',
          padding: '20px',
          marginBottom: '20px',
          display: 'block',
      }}>
        { decodedPayload ?
          <DecodedPayload decodedPayload={decodedPayload} />
          :
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <p className='detail-title'>Payload </p>
              <p className='detail-text'>{escapeEmptyDetail(event.payload)}</p>
            </Grid.Column>
          </Grid>
        }
      </Box>
    </>
  );
};

export default ContextualizationEventModel;
