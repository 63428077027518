import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { Button, Loader, Modal } from 'semantic-ui-react';
import { TextField, Grid, Checkbox, FormControlLabel } from '@mui/material';

import ErrorMessage from '../../components/Error/Error';
import { addNotableEvent } from '../../api/api'
import { validationSchema } from '../../constants/notableEventManualStep';
import DecodedPayload from '../../components/DecodedPayload/DecodedPayload';

const NotableEventManualStep = (props) => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [errorStatus, setErrorStatus] = useState();
  const [errorDetail, setErrorDetail] = useState();
  const [hasError, setHasError] = useState(false);

  const handleSubmit = (values, formikHelpers) => {

    addNotableEvent(props.payloadId, values)
      .then(() => {
        navigate(0);
      })
      .catch((err) => {
        if (err.response.data['error.key'] !== null) {
          formikHelpers.setErrors({[err.response.data['error.key']]: err.response.data.detail});
          formikHelpers.setSubmitting(false);
        } else if(err.response.data.fieldErrors !== null) {
          let errors = {};
          err.response.data.fieldErrors.forEach(el => {
            errors[el.field] = el.message;
          })
          formikHelpers.setErrors(errors);
          formikHelpers.setSubmitting(false);
        } else {
          setHasError(true)
          setErrorStatus(err.response.status)
          setErrorDetail(err.response.data.detail);
        }
        // formikHelpers.setSubmitting(false);
      })
      .finally(() => {
      setLoading(false);
    });
  }   

  return (
    <>
      {loading ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <>
        { hasError ? (
            <Modal
              open={props.open}
              onClose={props.onClose}
              className="formModal"
            >
            <Modal.Header>Add Notable Event</Modal.Header>
              <Modal.Content className="formModal-content">
                <ErrorMessage statusCode={errorStatus} detail={errorDetail}></ErrorMessage>
              </Modal.Content>
              <Modal.Actions>
                  <Button type='cancel' onClick={props.onClose}>
                    Close
                  </Button>
                </Modal.Actions>
            </Modal>
          ) : (
            <Formik
                initialValues={{payload: props.payload64 ?? '', content: '', isError: true}}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
              { formProps => ( 
                <form onSubmit={formProps.handleSubmit} noValidate >
                  <Modal
                      open={props.open}
                      onClose={props.onClose}
                      className="formModal"
                  >
                      <Modal.Header>Add Notable Event</Modal.Header>
                      <Modal.Content className="formModal-content" >
                        
                        <Grid container spacing={2}>
                          <Grid item md={12}>
                            <TextField
                                required
                                fullWidth
                                label='Reason'
                                id='content'
                                name='content'
                                placeholder='Reason'
                                value={formProps.values.content}
                                onChange={formProps.handleChange}
                                error={formProps.touched.content && Boolean(formProps.errors.content)}
                                helperText={formProps.touched.content && formProps.errors.content}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <TextField
                                fullWidth
                                label='Related Event Id'
                                id='relatedEventId'
                                name='relatedEventId'
                                placeholder='relatedEventId'
                                value={formProps.values.relatedEventId}
                                onChange={formProps.handleChange}
                                error={formProps.touched.relatedEventId && Boolean(formProps.errors.relatedEventId)}
                                helperText={formProps.touched.relatedEventId && formProps.errors.relatedEventId}
                            />
                          </Grid>
                          <Grid>
                            <FormControlLabel
                              value="isError"
                              control={
                                <Checkbox 
                                  defaultChecked
                                  id='isError'
                                  name='isError'
                                  value={formProps.values.isError}
                                  onChange={formProps.handleChange}
                                />
                              }
                              label="Is Error"
                              labelPlacement="start"
                            />
                          </Grid>
                          { !props.decodedPayload &&
                            <Grid item md={12}>
                              <TextField
                                  required
                                  fullWidth
                                  label='Payload'
                                  id='payload'
                                  name='payload'
                                  placeholder='Payload'
                                  value={formProps.values.payload}
                                  onChange={formProps.handleChange}
                                  error={formProps.touched.payload && Boolean(formProps.errors.payload)}
                                  helperText={formProps.touched.payload && formProps.errors.payload}
                              />
                            </Grid>
                          }
                        </Grid>
                        { props.decodedPayload &&
                          <DecodedPayload decodedPayload={props.decodedPayload} />
                        }
                      </Modal.Content>
                      <Modal.Actions>
                        <Button disabled={formProps.isSubmitting} type='cancel' onClick={props.onClose}>Cancel</Button>
                        <Button disabled={formProps.isSubmitting} className="actionButton" type='submit' onClick={formProps.handleSubmit}>Submit</Button>
                      </Modal.Actions>
                  </Modal>
                </form>
              )} 
            </Formik>
          )}
        </>
      )}
    </>
  )
}

export default NotableEventManualStep
