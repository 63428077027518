import React, { useState, useEffect } from 'react';
import { Button, Loader, Modal } from 'semantic-ui-react';
import { Grid } from '@mui/material';
import { Box, Tooltip } from '@material-ui/core/';
import { ToggleButtonGroup, ToggleButton, Pagination } from '@material-ui/lab/';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { TextField, MenuItem } from '@mui/material';

import StripedTable from '../../components/Table/StripedTable.jsx';
import ErrorMessage from '../../components/Error/Error';
import { deleteProductionEntity, listProductionEntities } from '../../api/api';
import { escapeEmptyDetail } from '../../utils/utils.jsx';
import { useUserDispatch, tabActive } from '../../context/UserContext';
import ProductionEntitiesDetails from './Details/ProductionEntitiesDetails.jsx';
import AddProductionEntity from './Add/AddProductionEntity.jsx';

const ProductionEntities = () => {
  const userDispatch = useUserDispatch();

  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(true);

  const [, setInitialRows] = useState();
  const [errorStatus, setErrorStatus] = useState();
  const [errorDetail, setErrorDetail] = useState();
  const [hasError, setHasError] = useState(false);

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedProdEntity, setSelectedProdEntity] = useState();

  // filter options
  const [typeArr, setTypeArr] = useState([]);
  const [locationArr, setLocationArr] = useState([]);
  // table filter
  const [searchedType, setSearchedType] = useState();
  const [searchedLocation, setSearchedLocation] = useState();
  // pagination
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState();

  useEffect(() => {
    if (window.location.pathname === '/production-entities') {
      tabActive(userDispatch, 1);
    }

    getProductionEntities();
    getFilters();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, page, searchedType, searchedLocation]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage]);

  const getProductionEntities = () => {

    setLoading(true);
    
    listProductionEntities(
      itemsPerPage,
      page - 1,
      searchedType,
      searchedLocation
    )
      .then((result) => {
        setInitialRows(result.data.content);
        setRows(result.data.content);
        setNoOfPages(result.data.totalPages);
      })
      .catch((err) => {
        setHasError(true);
        setErrorStatus(err.response.data.status);
        setErrorDetail(err.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  const getFilters = () => {

    setLoading(true);

    listProductionEntities(100000, '', '', '', '')
    .then((response) => {
      response.data.content.forEach((a, index) => {
        if(a.productionEntityType !== '') {
          typeArr.push({
            key: index,
            value: String(a.productionEntityType),
            text: a.productionEntityType,
          });
        }
        if(a.location !== '') {
          locationArr.push({
            key: index,
            value: String(a.location),
            text: a.location,
          });
        }
      });

      const uniqueTypeObj = [
        ...new Map(typeArr.map((item) => [item.text, item])).values(),
      ];
      setTypeArr(uniqueTypeObj);

      const uniqueLocationArrObj = [
        ...new Map(locationArr.map((item) => [item.text, item])).values(),
      ];
      setLocationArr(uniqueLocationArrObj);

    })
    .catch((err) => {
      setHasError(true);
      setErrorStatus(err.response.status);
      setErrorDetail(err.response.data.detail);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  const handleItemsPerPage = (event, newAmount) => {
    setItemsPerPage(newAmount);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const deleteProdEntity = () => {
    setLoading(true);

    deleteProductionEntity(selectedProdEntity)
      .then((res) => {
        setDeleteModalOpen(false);
        getProductionEntities();
      })
      .catch((err) => {
        setHasError(true);
        setErrorStatus(err.response.data.status);
        setErrorDetail(err.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      {loading || (rows?.length === undefined && !hasError) ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <>
          {hasError ? (
            <ErrorMessage statusCode={errorStatus} detail={errorDetail}></ErrorMessage>
          ) : (
            <div className='Home'>
              <section className='filter-section'>
                <TextField
                  select
                  label='Type'
                  placeholder='Type'
                  value={searchedType}
                  onChange={(valueSelected) => {
                    setSearchedType(valueSelected.target.value);
                  }}
                  >
                    <MenuItem key={"all"} value="">All</MenuItem>
                    {typeArr.map(el =>
                      <MenuItem key={el.key} value={el.value}>{el.text}</MenuItem>
                    )}
                </TextField>
                <TextField
                  select
                  label='Location'
                  placeholder='Location'
                  value={searchedLocation}
                  onChange={(valueSelected) => {
                    setSearchedLocation(valueSelected.target.value);
                  }}
                  >
                    <MenuItem key={"all"} value="">All</MenuItem>
                    {locationArr.map(el =>
                      <MenuItem key={el.key} value={el.value}>{el.text}</MenuItem>
                    )}
                </TextField>
              </section>
              <Grid container sx={{paddingRight: 2}}>
                <Grid item md={12}>
                  <section className='table-btn-wrapper'>
                    <Tooltip placement={'top'} arrow title='Add Production Entity'>
                      <button  onClick={() => setAddModalOpen(true)}> Add New </button>
                    </Tooltip>
                  </section>
                </Grid>
              </Grid>
              <section className='content'>
                {rows?.length !== undefined && !loading ? (
                  <>
                    <StripedTable
                      aria-label='simple table'
                      rowsClickable
                      headings={[
                        '',
                        '',
                        '',
                        '',
                        'Id',
                        'Name',
                        'Type',
                        'Location',
                        'Lat Long',
                        ''
                      ]}
                      rows={rows?.map((data, index) => {
                        if (data) {
                          return {
                            elements: [
                              <p index={index}></p>,
                              <p index={index}></p>,
                              <p index={index}></p>,
                              <p index={index}></p>,
                              <p>{escapeEmptyDetail(data.productionEntityId)}</p>,
                              <p>{escapeEmptyDetail(data.name)}</p>,
                              <p>{escapeEmptyDetail(data.productionEntityType)}</p>,
                              <p>{escapeEmptyDetail(data.location)}</p>,
                              <p>{escapeEmptyDetail(data.latlong)}</p>,
                              <section className='export-btn-wrapper'>
                                <Tooltip placement={'top'} arrow title='Edit Production Entity'>
                                  <button className='sm-btn' onClick={(e) => {e.stopPropagation(); setSelectedProdEntity(data.productionEntityId); setEditModalOpen(true)}}> <EditIcon /> </button>
                                </Tooltip>
                                <Tooltip placement={'top'} arrow title='Delete Production Entity'>
                                  <button className='sm-btn danger' onClick={(e) => {e.stopPropagation(); setSelectedProdEntity(data.productionEntityId); setDeleteModalOpen(true)}} > <DeleteIcon /> </button>
                                </Tooltip>
                              </section>
                            ],
                            onClick: () => {
                              setSelectedProdEntity(data.productionEntityId);
                              setDetailModalOpen(true);
                            },
                          };
                        } else {
                          return {
                            elements: [],
                          };
                        }
                      })}
                    ></StripedTable>
                    <Grid container spacing={2}>
                      <Grid item md={4}/>
                      <Grid item md={4}>
                        <Box component='div'>
                          <Pagination
                            count={noOfPages}
                            page={page}
                            onChange={handleChangePage}
                            defaultPage={0}
                            color='primary'
                            size='small'
                          />
                        </Box>
                      </Grid>
                      {rows?.length > 0 &&
                        <Grid item md={4} p={2}>
                          <Box component='div' justifyContent={'right'} className='perpage'>
                            <Tooltip
                              placement={'right'}
                              arrow
                              title={<span>View per page</span>}
                            >
                              <ToggleButtonGroup
                                value={itemsPerPage}
                                exclusive
                                onChange={handleItemsPerPage}
                                aria-label='text alignment'
                                size='small'
                              >
                                <ToggleButton value={5} aria-label='left aligned'>
                                  5
                                </ToggleButton>
                                <ToggleButton value={50} aria-label='centered'>
                                  50
                                </ToggleButton>
                                <ToggleButton value={100} aria-label='justified'>
                                  100
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Tooltip>
                          </Box>
                        </Grid>
                      }
                    </Grid>
                    { detailModalOpen &&
                      <ProductionEntitiesDetails 
                        open={detailModalOpen}
                        onClose={() => setDetailModalOpen(false)}
                        onCloseSuccessfully={() => {setDetailModalOpen(false); getProductionEntities();}}
                        productionEntityId={selectedProdEntity}
                      />
                    }
                    { addModalOpen &&
                      <AddProductionEntity
                        open={addModalOpen}
                        onClose={() => setAddModalOpen(false)}
                        onCloseSuccessfully={() => {setAddModalOpen(false); getProductionEntities(); getFilters();}}
                      />
                    }
                    { editModalOpen &&
                      <AddProductionEntity 
                        open={editModalOpen}
                        onClose={() => setEditModalOpen(false)}
                        onCloseSuccessfully={() => {setEditModalOpen(false); getProductionEntities(); getFilters();}}
                        productionEntityId={selectedProdEntity}
                      />
                    }
                    { deleteModalOpen &&
                      <Modal
                      open={deleteModalOpen}
                      onClose={() => setDeleteModalOpen(false)}
                      >
                        <Modal.Header>Delete Production Entity</Modal.Header>
                        <Modal.Content>
                          Are you sure you want to delete Production Entity: '{selectedProdEntity}' ?
                        </Modal.Content>
                        <Modal.Actions>
                          <Button onClick={() => setDeleteModalOpen(false)}>
                            Cancel
                          </Button>
                          <Button negative onClick={deleteProdEntity}>
                            Delete
                          </Button>
                        </Modal.Actions>
                      </Modal>
                    }
                  </>
                ) : (
                  <>
                    {rows?.length > 0 ? (
                      <div className='empty-table'>
                        <Loader active indeterminate size='small' />
                      </div>
                    ) : (
                      <StripedTable rows={[]}></StripedTable>
                    )}
                  </>
                )}
              </section>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ProductionEntities;