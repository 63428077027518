/* eslint-disable no-unused-vars */
import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  Font
} from "@react-pdf/renderer";

import Logo from "../images/fiutur_logo.png";
import Kanit from "../fonts/Kanit-Regular.ttf";
import { dateFormatter, escapeEmptyDetail } from '../utils/utils';

const IssuedCertificatePDF = (certificate) => {

  Font.register({
    family: 'Kanit',
    src: Kanit
  })

  // Create styles
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#03241C",
      fontFamily: 'Kanit'
    },
    logo: {
      width: "100px",
      height: "auto",
      margin: 20,
    },
    section: {
      margin: 20,
      marginBottom: 10,
      padding: 10,
      borderRadius: 6,
      fontSize: "10px",
      fontWeight: "normal",
      backgroundColor: "#FFFFFF",
    },
    content: {
      flexDirection: "row",
    },
    col: {
      marginBottom: 10,
      marginRight: 20,
    },
    eventTitle: {
      fontSize: "14px",
      fontWeight: "bold",
    },
    eventTextTitle: {
      fontSize: "12px",
      color: "#03241C",
      marginTop: 10,
    },
    eventText: {
      marginTop: 5,
      fontWeight: "light",
    },
    eventTextTab: {
      marginTop: 5,
      marginBottom: 5,
      marginLeft: 10,
      fontWeight: "light",
    },
    eventTextAmt: {
      marginTop: 5,
    },
    pdfTitle: {
      margin: 20,
      marginBottom: 10,
      color: "#FFFFFF",
      fontSize: "14px",
      fontWeight: "bold",
    },
  });

  const IssuedCertificateToPDF = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.logo} src={Logo} alt="logo" />
        <View style={styles.pdfTitle}>
          <Text style={styles.eventText}>Issued Certificate Id</Text>
          <Text style={styles.eventText}>{certificate.issuedCertificateId}</Text>
        </View>
        <View style={styles.col}>
          <View style={styles.section}>
            <View style={styles.content}>
              <View style={styles.col}>
                <Text style={styles.eventTextTitle}>
                  Master Certificate Id:
                </Text>
                <Text style={styles.eventText}>
                  {escapeEmptyDetail(certificate.masterCertificateId)}
                </Text>
                {/* <Text style={styles.eventTextTitle}>
                  Certifying Body Party Id:
                </Text>
                <Text style={styles.eventText}>
                  {escapeEmptyDetail(certificatecertificate.certifyingBodyPartyId)}
                </Text> */}
                <Text style={styles.eventTextTitle}>
                  Certifying Body Party Name:
                </Text>
                <Text style={styles.eventText}>
                  {escapeEmptyDetail(certificate.certifyingBodyPartyName)}
                </Text>
                {/* <Text style={styles.eventTextTitle}>
                  Certificate Holder Party Id:
                </Text>
                <Text style={styles.eventText}>
                  {escapeEmptyDetail(certificate.certificateHolderPartyId)}
                </Text> */}
                <Text style={styles.eventTextTitle}>
                  Certificate Holder Party Name:
                </Text>
                <Text style={styles.eventText}>
                  {escapeEmptyDetail(certificate.certificateHolderPartyName)}
                </Text>
                <Text style={styles.eventTextTitle}>
                  Certificate Name:
                </Text>
                <Text style={styles.eventText}>
                  {escapeEmptyDetail(certificate.certificateName)}
                </Text>
                <Text style={styles.eventTextTitle}>
                  Certificate Type:
                </Text>
                <Text style={styles.eventText}>
                  {escapeEmptyDetail(certificate.certificateType)}
                </Text>
                <Text style={styles.eventTextTitle}>
                  Certificate Rating:
                </Text>
                <Text style={styles.eventText}>
                  {escapeEmptyDetail(certificate.certificateRating)}
                </Text>
                <Text style={styles.eventTextTitle}>
                  Certificate Version:
                </Text>
                <Text style={styles.eventText}>
                  {escapeEmptyDetail(certificate.certificateVersion)}
                </Text>
                <Text style={styles.eventTextTitle}>
                  Certificate Name:
                </Text>
                <Text style={styles.eventText}>
                  {escapeEmptyDetail(certificate.certificateName)}
                </Text>
                <Text style={styles.eventTextTitle}>
                  External Certificate Id:
                </Text>
                <Text style={styles.eventText}>
                  {escapeEmptyDetail(certificate.externalCertificateId)}
                </Text>
                <Text style={styles.eventTextTitle}>
                  Production Entities:
                </Text>
                { certificate.productionEntities?.length > 0 ?
                  <>
                    {certificate.productionEntities?.map((el, index) => {
                      return (
                        <Text key={index} style={styles.eventTextTab}>
                          Production Entity Id: {escapeEmptyDetail(el.productionEntityId)}{'\n'}
                          External Id: {escapeEmptyDetail(el.externalId)}{'\n'}
                          Production Entity Name: {escapeEmptyDetail(el.name)}{'\n'}
                          Production Entity Type: {escapeEmptyDetail(el.productionEntityType)}{'\n'}
                          Location: {escapeEmptyDetail(el.location)}{'\n'}
                          Lat Long: {escapeEmptyDetail(el.latlong)}{'\n'}
                          Volume Unit: {escapeEmptyDetail(el.volumeUnit)}{'\n'}
                          Value: {escapeEmptyDetail(el.value)}{'\n'}
                          Supplemental Data: {'\n'}
                          {el.supplementalData?.map(item => {
                            return `- ${item.name}: ${item.value}\n`
                          })}
                        </Text>
                      )
                    })}
                  </>
                :
                  <Text style={styles.eventText}>
                  -
                  </Text>
                }
                <Text style={styles.eventTextTitle}>
                  Acessor Name:
                </Text>
                <Text style={styles.eventText}>
                  {escapeEmptyDetail(certificate.assessorName)}
                </Text>
                <Text style={styles.eventTextTitle}>
                  Facility Id:
                </Text>
                <Text style={styles.eventText}>
                  {escapeEmptyDetail(certificate.facilityId)}
                </Text>
                <Text style={styles.eventTextTitle}>
                  Status:
                </Text>
                <Text style={styles.eventText}>
                  {escapeEmptyDetail(certificate.status)}
                </Text>
                <Text style={styles.eventTextTitle}>
                  Activation Date:
                </Text>
                <Text style={styles.eventText}>
                  {dateFormatter(certificate.activationDate)}
                </Text>
                <Text style={styles.eventTextTitle}>
                  Expiration Date:
                </Text>
                <Text style={styles.eventText}>
                  {dateFormatter(certificate.expirationDate)}
                </Text>
                <Text style={styles.eventTextTitle}>
                  External Certificate Url:
                </Text>
                <Text style={styles.eventText}>
                  {escapeEmptyDetail(certificate.externalCertificateUrl)}
                </Text>
                <Text style={styles.eventTextTitle}>
                  Data:
                </Text>
                <Text style={styles.eventText}>
                  {escapeEmptyDetail(certificate.data)}
                </Text>
                <Text style={styles.eventTextTitle}>
                  Bounding Box:
                </Text>
                { certificate.boundingBox?.length > 0 ?
                  <Text  style={styles.eventTextTab}>
                    {certificate.boundingBox?.map((el, index) => {
                      return (
                        <span key={index}>{`'${el}'`}{index+1 !== (certificate.boundingBox).length && '; '}</span>
                      )
                    })}
                  </Text>
                :
                  <Text style={styles.eventText}>
                  -
                  </Text>
                }
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  return <>{IssuedCertificateToPDF()}</>;
};

export default IssuedCertificatePDF;