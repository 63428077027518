import * as yup from 'yup';


export const validationSchema = yup.object().shape({
  serviceName: yup
    .string()
    .required('Service Name is required'),
  payload: yup
    .string()
    .required('Payload is required'),
  startingValues: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('Name is required'),
        value: yup.string().required('Value is required'),
      })
    )
    .min(1, 'Starting Values is required'),
    result: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('Name is required'),
        value: yup.string().required('Value is required'),
      })
    )
    .min(1, 'Result is required'),
    supportingInformation: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('Name is required'),
        value: yup.string().required('Value is required'),
      })
    )
    .min(1, 'Supporting Inofrmation is required'),
});
