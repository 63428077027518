import * as yup from 'yup';


export const addValidationSchema = yup.object().shape({
  certifyingBodyPartyId: yup
    .string()
    .required('Certifying Body Party is required'),
  certificateHolderPartyId: yup
    .string()
    .required('Certificate Holder Party is required'),
  name: yup
    .string()
    .required('Production Entity Name is required'),
  externalId: yup
    .string()
    .required('External Id is required'),
  productionEntityType: yup
    .string()
    .required('Production Entity Type is required'),
  supplementalData: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string()
        .when(['value'], {
          is: (a) => a !== undefined,
          then: yup.string().required("Name is required when Value is filled"),
          otherwise: yup.string()
        }),
        value: yup.string()
        .when(['name'], {
          is: (a) => a !== undefined,
          then: yup.string().required("Value is required when Name is filled"),
          otherwise: yup.string()
        }),
      }, ['name', 'value'])
    ),
  lat: yup
    .number()
    .min(-90, 'Lat value should be greater than -90')
    .max(90, 'Lat value should be less than 90')
    .when(['long'], {
      is: (a) => a !== undefined,
      then: yup.number().required("Lat is required when Long is filled"),
      otherwise: yup.number()
    }),
  long: yup
    .number()
    .min(-180, 'Long value should be greater than -180')
    .max(180, 'Long value should be less than 180')
    .when(['lat'], {
      is: (a) => a !== undefined,
      then: yup.number().required("Long is required when Lat is filled"),
      otherwise: yup.number()
    }),
}, ['lat', 'long']);

export const editValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Production Entity Name is required'),
  externalId: yup
    .string()
    .required('External Id is required'),
  productionEntityType: yup
    .string()
    .required('Production Entity Type is required'),
  supplementalData: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string()
        .when(['value'], {
          is: (a) => a !== undefined,
          then: yup.string().required("Name is required when Value is filled"),
          otherwise: yup.string()
        }),
        value: yup.string()
        .when(['name'], {
          is: (a) => a !== undefined,
          then: yup.string().required("Value is required when Name is filled"),
          otherwise: yup.string()
        }),
      }, ['name', 'value'])
    ),
  lat: yup
    .number()
    .min(-90, 'Lat value should be greater than -90')
    .max(90, 'Lat value should be less than 90')
    .when(['long'], {
      is: (a) => a !== undefined,
      then: yup.number().required("Lat is required when Long is filled"),
      otherwise: yup.number()
    }),
  long: yup
    .number()
    .min(-180, 'Long value should be greater than -180')
    .max(180, 'Long value should be less than 180')
    .when(['lat'], {
      is: (a) => a !== undefined,
      then: yup.number().required("Long is required when Lat is filled"),
      otherwise: yup.number()
    }),
}, ['lat', 'long']);

export const productionEntityTypes = [
    {
      key: 0,
      value: 'RIG',
      text: 'RIG'
    },{
      key: 1,
      value: 'PLANT',
      text: 'PLANT'
    },{
      key: 2,
      value: 'WELL',
      text: 'WELL'
    }
];