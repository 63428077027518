import React, { useState, useEffect } from 'react';
import { Button, Grid, Loader, Modal } from 'semantic-ui-react';

import { useUserDispatch, tabActive } from '../../../context/UserContext';
import { listMasterCertificate, listProductionEntities } from '../../../api/api';
import { dateFormatter, escapeEmptyDetail } from '../../../utils/utils';
import DetailItem from '../../../components/DetailItem/DetailItem';
import ErrorMessage from '../../../components/Error/Error';

const MasterCertificatesDetails = (props) => {
  const userDispatch = useUserDispatch();

  const [loading, setLoading] = useState(true);
  const [masterCertificate, setMasterCertificate] = useState();
  const [errorStatus, setErrorStatus] = useState();
  const [errorDetail, setErrorDetail] = useState();
  const [hasError, setHasError] = useState(false);
  const [productionEntities, setProductionEntities] = useState();

  useEffect(() => {
    if (window.location.pathname === `/master-certificates`) {
      tabActive(userDispatch, 2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let id = props.masterCertificateId;
    setLoading(true);

    if (id) {
      // getting workflow data to display steps details
      listMasterCertificate(id)
        .then((res) => {
          setMasterCertificate(res.data);
        })
        .then(
          listProductionEntities('10000')
          .then((res) => {
            buildProductionEntityList(res.data.content);
          })
        )
        .catch((err) => {
          setHasError(true);
          setErrorStatus(err.response.status);
          setErrorDetail(err.response.data.detail);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const buildProductionEntityList = (prods) => {
    const productionEntities = prods.map((el, index) => {
      return { key: index, text: el.name, value: el.productionEntityId};
    })
    setProductionEntities(productionEntities);
  }

  return (
    <div className='payload'>
      {loading || !productionEntities || (!masterCertificate && !hasError) ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <>
          { hasError ? (
              <Modal
                open={props.open}
                onClose={props.onClose}
                className="formModal"
              >
                <Modal.Header>Master Certificate Details</Modal.Header>
                <Modal.Content className="formModal-content">
                  <ErrorMessage statusCode={errorStatus} detail={errorDetail}></ErrorMessage>
                </Modal.Content>
                <Modal.Actions>
                    <Button type='cancel' onClick={props.onClose}>
                      Close
                    </Button>
                  </Modal.Actions>
              </Modal>
            ) : (
              <Modal
                open={props.open}
                onClose={props.onClose}
                className='formModal'
              >
                <Modal.Header>Master Certificate Details</Modal.Header>
                <Modal.Content className='formModal-content'>
                  <Grid container spacing={2}>
                    <Grid md={12} className='full-row'>
                      <DetailItem title='Id' content={escapeEmptyDetail(masterCertificate.masterCertificateId)} />
                    </Grid>
                    <Grid md={12} className='full-row'>
                      <DetailItem title='Certifying Body Party' content={escapeEmptyDetail(masterCertificate.certifyingBodyPartyName)} />
                    </Grid>
                    <Grid md={12} className='full-row'>
                      <DetailItem title='Certificate Holder Party' content={escapeEmptyDetail(masterCertificate.certificateHolderPartyName)} />
                    </Grid>
                    <Grid md={12} className='full-row'>
                      <DetailItem title='Certificate Type' content={escapeEmptyDetail(masterCertificate.certificateType)} />
                    </Grid>
                    <Grid md={12} className='full-row'>
                      <DetailItem title='Certificate Rating' content={escapeEmptyDetail(masterCertificate.certificateRating)} />
                    </Grid>
                    <Grid md={12} className='full-row'>
                      <DetailItem title='Certificate Version' content={escapeEmptyDetail(masterCertificate.certificateVersion)} />
                    </Grid>
                    <Grid md={12} className='full-row'>
                      <DetailItem title='Certificate Name' content={escapeEmptyDetail(masterCertificate.certificateName)} />
                    </Grid>
                    <Grid md={12} className='full-row'>
                      <DetailItem title='External Certificate Id' content={escapeEmptyDetail(masterCertificate.externalCertificateId)} />
                    </Grid>
                    <Grid md={12}>
                      <DetailItem
                        title='Production Entities'
                        content={masterCertificate.productionEntities?.length > 0 ? 
                                  masterCertificate.productionEntities.map((el, index) => {
                                    return Boolean(productionEntities.find( item => item.value === el)) &&
                                      <span key={index}>{productionEntities ? productionEntities.find( item => item.value === el)?.text : ''}{index+1 !== (masterCertificate.productionEntities).length && ', '}</span>
                                  })
                                  :
                                  '-'
                                }
                      />
                    </Grid>
                    <Grid md={12} className='full-row'>
                      <DetailItem title='Acessor Name' content={escapeEmptyDetail(masterCertificate.assessorName)} />
                    </Grid>
                    <Grid md={12} className='full-row'>
                      <DetailItem title='Facility Id' content={escapeEmptyDetail(masterCertificate.facilityId)} />
                    </Grid>
                    <Grid md={12} className='full-row'>
                      <DetailItem title='Status' content={escapeEmptyDetail(masterCertificate.status)} />
                    </Grid>
                    <Grid md={12} className='full-row'>
                      <DetailItem title='Activation Date' content={dateFormatter(masterCertificate.activationDate)} />
                    </Grid>
                    <Grid md={12} className='full-row'>
                      <DetailItem title='Expiration Date' content={dateFormatter(masterCertificate.expirationDate)} />
                    </Grid>
                    <Grid md={12} className='full-row'>
                      <DetailItem title='External Certificate URL' content={escapeEmptyDetail(masterCertificate.externalCertificateUrl)} />
                    </Grid>
                    <Grid md={12} className='full-row'>
                      <DetailItem title='Data' content={escapeEmptyDetail(masterCertificate.data)} />
                    </Grid>
                    <Grid md={12} className='full-row'>
                      <DetailItem
                        title='Bounding Box'
                        content={masterCertificate.boundingBox?.length > 0 ?
                          masterCertificate.boundingBox.map((el, index) => {
                            return <span key={index}>{`'${el}'`}{index+1 !== (masterCertificate.boundingBox).length && '; '}</span>
                          }) 
                          :
                          '-'
                        }
                      />
                    </Grid>
                  </Grid>
                </Modal.Content>
                <Modal.Actions>
                  <Button type='cancel' onClick={props.onClose}>
                    Close
                  </Button>
                </Modal.Actions>
              </Modal>
            )
          }
        </>
      )}
    </div>
  )
}

export default MasterCertificatesDetails
