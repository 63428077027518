import * as yup from 'yup';


export const validationSchema = yup.object().shape({
  payload: yup
    .string()
    .required('Payload is required'),
  content: yup
    .string()
    .required('Content is required'),
});
