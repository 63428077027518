import React from 'react';
import Logo from '../../images/fiutur_logo.png';
import { useAuth0 } from '@auth0/auth0-react';

const Header = () => {
  const { user, logout } = useAuth0();

  return (
    <div className='header'>
      <img src={Logo} alt='Logo' className='logo' />
      <div className="user_menu">
        <span className="item">{user ? user.email : ''}</span>
        <span className="item"> | </span>
        <span className="item clickable" onClick={() => logout()} >Logout</span>
      </div>
    </div>
  );
};

export default Header;
