import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FieldArray } from 'formik';
import { Button, Loader, Modal } from 'semantic-ui-react';
import { TextField, Grid } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import ErrorMessage from '../../components/Error/Error';
import { addGeneralManualStep, addNotableEvent } from '../../api/api'
import { escapeEmptyDetail } from '../../utils/utils';
import { validationSchema as contextualizationValidationSchema } from '../../constants/contextualizationManualStep';
import { validationSchema as notableEventValidationSchema } from '../../constants/notableEventManualStep';
import DecodedPayload from '../../components/DecodedPayload/DecodedPayload';
import DecodedPayloadEditableTable from '../../components/DecodedPayload/DecodedPayloadEditableTable';
import DetailItem from '../../components/DetailItem/DetailItem';

const ContextualizationManualStep = (props) => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [errorStatus, setErrorStatus] = useState();
  const [errorDetail, setErrorDetail] = useState();
  const [hasError, setHasError] = useState(false);
  const [payload, setPayload] = useState(props.decodedPayload);
  const [approval, setApproval] = useState();

  const handleSubmit = (values, formikHelpers) => {
    
    delete values.title;
    delete values.description;

    if(approval === 'true') {
      var encodedPayload;
      delete values.content;

      if (payload && payload.attributes !== undefined) {
        const auxAttributes = payload.attributes.map(({id, ...attrs}) => attrs)
        const auxPayload = {...payload, attributes: auxAttributes}
        encodedPayload = Buffer.from(JSON.stringify(auxPayload)).toString("base64");
      } else {
        encodedPayload = values.payload;
      }
      
      values = {
        ...values,
        payload: encodedPayload
      };

      addGeneralManualStep(props.payloadId, values)
        .then(() => {
          navigate(0);
        })
        .catch((err) => {
          setHasError(true);
          formikHelpers.setSubmitting(false);
        })
        .finally(() => {
        setLoading(false);
      });
    } else if(approval === 'false') {
      
      values = {...values, isError: true};

      delete values['@type'];
      delete values.order;
      delete values.isManual;
      delete values.serviceName;
      delete values.startingValues;
      delete values.result;
      delete values.supportingInformation;
      
      addNotableEvent(props.payloadId, values)
        .then(() => {
          navigate(0);
        })
        .catch((err) => {
          if (err.response.data['error.key'] !== null) {
            formikHelpers.setErrors({[err.response.data['error.key']]: err.response.data.detail});
            formikHelpers.setSubmitting(false);
          } else if(err.response.data.fieldErrors !== null) {
            let errors = {};
            err.response.data.fieldErrors.forEach(el => {
              errors[el.field] = el.message;
            })
            formikHelpers.setErrors(errors);
            formikHelpers.setSubmitting(false);
          } else {
            setHasError(true)
            setErrorStatus(err.response.status)
            setErrorDetail(err.response.data.detail);
          }
          // formikHelpers.setSubmitting(false);
        })
        .finally(() => {
        setLoading(false);
      });
    }
  }

  const onChangeAttributes = (newAttributes) => {
    setPayload({...payload, attributes: newAttributes})
  }

  return (
    <>
      {loading ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <>
        { hasError ? (
            <Modal
              open={props.open}
              onClose={props.onClose}
              className="formModal"
            >
            <Modal.Header>{props.workflowStep['@type'].slice(0, props.workflowStep['@type'].length - 4)} Manual Step</Modal.Header>
              <Modal.Content className="formModal-content">
                <ErrorMessage statusCode={errorStatus} detail={errorDetail}></ErrorMessage>
              </Modal.Content>
              <Modal.Actions>
                  <Button type='cancel' onClick={props.onClose}>
                    Close
                  </Button>
                </Modal.Actions>
            </Modal>
          ) : (
            <Formik
                initialValues={
                  {
                    payload: props.payload64 ?? '',
                    '@type': props.workflowStep['@type'].slice(0, props.workflowStep['@type'].length - 4)+'Event',
                    order: props.workflowStep.order ?? '',
                    isManual: props.workflowStep.isManual ?? '',
                    serviceName: '',
                    startingValues: [{name:'', value:''}],
                    result: [{name:'', value:''}],
                    supportingInformation: [{name:'', value:''}],
                    content: '',
                    title: props.workflowStep.title,
                    description: props.workflowStep.description
                  }
                }
                validationSchema={approval === 'true' ? contextualizationValidationSchema : notableEventValidationSchema}
                onSubmit={handleSubmit}
            >
              { formProps => ( 
                <form onSubmit={formProps.handleSubmit} noValidate >
                  <Modal
                      open={props.open}
                      onClose={props.onClose}
                      className="formModal"
                  >
                    <Modal.Header>{props.workflowStep['@type'].slice(0, props.workflowStep['@type'].length - 4)} Manual Step</Modal.Header>
                    <Modal.Content className="formModal-content" >
                      <Grid container spacing={2}>
                        <Grid item md={12}>
                          <DetailItem title='Title' content={escapeEmptyDetail(formProps.values.title)} />
                        </Grid>
                        <Grid item md={12}>
                          <DetailItem title='Description' content={escapeEmptyDetail(formProps.values.description)} />
                        </Grid>
                        { !payload &&
                              <Grid item md={12}>
                                <TextField
                                  required
                                  fullWidth
                                  label='Payload'
                                  id='payload'
                                  name='payload'
                                  placeholder='Payload'
                                  value={formProps.values.payload}
                                  onChange={formProps.handleChange}
                                  error={formProps.touched.payload && Boolean(formProps.errors.payload)}
                                  helperText={formProps.touched.payload && formProps.errors.payload}
                                />
                              </Grid>
                            }
                            { payload &&
                              <Grid item md={12}>
                                <DecodedPayload decodedPayload={props.decodedPayload} />
                              </Grid>
                            }
                        <Grid item md={12}>
                          <ToggleButtonGroup
                            value={approval}
                            exclusive
                            onChange={(e) => setApproval(e.target.value)}
                            aria-label="action"
                          >
                            <ToggleButton value='true'>
                              Approve
                            </ToggleButton>
                            <ToggleButton value='false'>
                              Reject
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Grid>
                        { approval === 'true' ?
                          <>
                            <Grid item md={12}>
                              <TextField
                                required
                                fullWidth
                                label='Service Name'
                                id='serviceName'
                                name='serviceName'
                                placeholder='Service Name'
                                value={formProps.values.serviceName}
                                error={formProps.touched.serviceName && Boolean(formProps.errors.serviceName)}
                                helperText={formProps.touched.serviceName && formProps.errors.serviceName}
                                onChange={formProps.handleChange}
                              />
                            </Grid>
                            <Grid item md={12}>
                              <p>Starting Values</p>
                            </Grid>
                            <FieldArray
                              label='Starting Values'
                              id='startingValues'
                              name="startingValues"
                              render={arrayHelpers => (
                                <>
                                  {formProps.values.startingValues?.map((stValue, index) => (
                                    <Grid container item md={12} spacing={2} display='contents' alignItems='flex-end' key={index}>
                                      <Grid item md={5}>
                                        <TextField 
                                          fullWidth
                                          required
                                          label='Name'
                                          id={`startingValues[${index}].name`}
                                          name={`startingValues[${index}].name`} 
                                          value={stValue.name || ''}
                                          error={formProps.touched.startingValues && formProps.errors.startingValues !== undefined && Boolean(formProps.errors.startingValues[index]?.name)}
                                          helperText={formProps.touched.startingValues && formProps.errors.startingValues !== undefined && formProps.errors.startingValues[index]?.name}
                                          onChange={formProps.handleChange}
                                          onBlur={formProps.handleBlur}
                                        />
                                      </Grid>
                                      <Grid item md={5}>
                                        <TextField 
                                          fullWidth
                                          required
                                          label='Value'
                                          id={`startingValues[${index}].value`}
                                          name={`startingValues.${index}.value`}
                                          value={stValue.value || ''}
                                          error={formProps.touched.startingValues && formProps.errors.startingValues !== undefined && Boolean(formProps.errors.startingValues[index]?.value)}
                                          helperText={formProps.touched.startingValues && formProps.errors.startingValues !== undefined && formProps.errors.startingValues[index]?.value}
                                          onChange={formProps.handleChange} 
                                          onBlur={formProps.handleBlur}
                                        />
                                      </Grid> 
                                      <Grid item md={2}>
                                        {formProps.values.startingValues?.length > 1 &&
                                          <div
                                            className='font-icon-wrapper'
                                            onClick={() => arrayHelpers.remove(index)}
                                          >
                                            <IconButton aria-label='delete'>
                                              <DeleteIcon />
                                            </IconButton>
                                          </div>
                                        }
                                      </Grid>
                                    </Grid>
                                  ))}

                                  <Grid item md={12}>
                                    <Button onClick={() => arrayHelpers.push({ name: '', value: '' })} className='actionButton'>
                                      Add more
                                    </Button>
                                  </Grid>
                                </>
                              )}
                            />
                            <Grid item md={12}>
                              <p>Result</p>
                            </Grid>
                            <FieldArray
                              label='Result'
                              id='result'
                              name="result"
                              render={arrayHelpers => (
                                <>
                                  {formProps.values.result?.map((result, index) => (
                                    <Grid container item md={12} spacing={2} display='contents' alignItems='flex-end' key={index}>
                                      <Grid item md={5}>
                                        <TextField 
                                          fullWidth
                                          required
                                          label='Name'
                                          id={`result[${index}].name`}
                                          name={`result[${index}].name`} 
                                          value={result.name || ''}
                                          error={formProps.touched.result && formProps.errors.result !== undefined && Boolean(formProps.errors.result[index]?.name)}
                                          helperText={formProps.touched.result && formProps.errors.result !== undefined && formProps.errors.result[index]?.name}
                                          onChange={formProps.handleChange}
                                          onBlur={formProps.handleBlur}
                                        />
                                      </Grid>
                                      <Grid item md={5}>
                                        <TextField 
                                          fullWidth
                                          required
                                          label='Value'
                                          id={`result[${index}].value`}
                                          name={`result.${index}.value`}
                                          value={result.value || ''}
                                          error={formProps.touched.result && formProps.errors.result !== undefined && Boolean(formProps.errors.result[index]?.value)}
                                          helperText={formProps.touched.result && formProps.errors.result !== undefined && formProps.errors.result[index]?.value}
                                          onChange={formProps.handleChange} 
                                          onBlur={formProps.handleBlur}
                                        />
                                      </Grid> 
                                      <Grid item md={2}>
                                        {formProps.values.result?.length > 1 &&
                                          <div
                                            className='font-icon-wrapper'
                                            onClick={() => arrayHelpers.remove(index)}
                                          >
                                            <IconButton aria-label='delete'>
                                              <DeleteIcon />
                                            </IconButton>
                                          </div>
                                        }
                                      </Grid>
                                    </Grid>
                                  ))}

                                  <Grid item md={12}>
                                    <Button onClick={() => arrayHelpers.push({ name: '', value: '' })} className='actionButton'>
                                      Add more
                                    </Button>
                                  </Grid>
                                </>
                              )}
                            />
                            <Grid item md={12}>
                              <p>Supporting Information</p>
                            </Grid>
                            <FieldArray
                              label='Supporting Information'
                              id='supportingInformation'
                              name="supportingInformation"
                              render={arrayHelpers => (
                                <>
                                  {formProps.values.supportingInformation?.map((sInformation, index) => (
                                    <Grid container item md={12} spacing={2} display='contents' alignItems='flex-end' key={index}>
                                      <Grid item md={5}>
                                        <TextField 
                                          fullWidth
                                          required
                                          label='Name'
                                          id={`supportingInformation[${index}].name`}
                                          name={`supportingInformation[${index}].name`} 
                                          value={sInformation.name || ''}
                                          error={formProps.touched.supportingInformation && formProps.errors.supportingInformation !== undefined && Boolean(formProps.errors.supportingInformation[index]?.name)}
                                          helperText={formProps.touched.supportingInformation && formProps.errors.supportingInformation !== undefined && formProps.errors.supportingInformation[index]?.name}
                                          onChange={formProps.handleChange}
                                          onBlur={formProps.handleBlur}
                                        />
                                      </Grid>
                                      <Grid item md={5}>
                                        <TextField 
                                          fullWidth
                                          required
                                          label='Value'
                                          id={`supportingInformation[${index}].value`}
                                          name={`supportingInformation.${index}.value`}
                                          value={sInformation.value || ''}
                                          error={formProps.touched.supportingInformation && formProps.errors.supportingInformation !== undefined && Boolean(formProps.errors.supportingInformation[index]?.value)}
                                          helperText={formProps.touched.supportingInformation && formProps.errors.supportingInformation !== undefined && formProps.errors.supportingInformation[index]?.value}
                                          onChange={formProps.handleChange} 
                                          onBlur={formProps.handleBlur}
                                        />
                                      </Grid> 
                                      <Grid item md={2}>
                                        {formProps.values.supportingInformation?.length > 1 &&
                                          <div
                                            className='font-icon-wrapper'
                                            onClick={() => arrayHelpers.remove(index)}
                                          >
                                            <IconButton aria-label='delete'>
                                              <DeleteIcon />
                                            </IconButton>
                                          </div>
                                        }
                                      </Grid>
                                    </Grid>
                                  ))}

                                  <Grid item md={12}>
                                    <Button onClick={() => arrayHelpers.push({ name: '', value: '' })} className='actionButton'>
                                      Add more
                                    </Button>
                                  </Grid>
                                </>
                              )}
                            />
                            { payload &&
                              <>
                              { payload.attributes !== undefined &&
                                <>
                                  <Grid item md={12}>
                                    <p>Attributes</p>
                                  </Grid>
                                  <Grid item md={12}>
                                    <DecodedPayloadEditableTable attributes={payload.attributes} onChangeAttributes={onChangeAttributes} />
                                  </Grid>
                                </>
                              }
                              </>
                            }
                          </>
                        : approval === 'false' ?
                          <Grid item md={12}>
                            <TextField
                                required
                                fullWidth
                                label='Reason'
                                id='content'
                                name='content'
                                placeholder='Reason'
                                value={formProps.values.content}
                                onChange={formProps.handleChange}
                                error={formProps.touched.content && Boolean(formProps.errors.content)}
                                helperText={formProps.touched.content && formProps.errors.content}
                            />
                          </Grid>
                        :
                            <></>
                        }
                      </Grid>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button disabled={formProps.isSubmitting} type='cancel' onClick={props.onClose}>Cancel</Button>
                      <Button disabled={formProps.isSubmitting || approval === undefined} className="actionButton" type='submit' onClick={formProps.handleSubmit}>Submit</Button>
                    </Modal.Actions>
                  </Modal>
                </form>
              )} 
            </Formik>
          )}
        </>
      )}
    </>
  )
}

export default ContextualizationManualStep
