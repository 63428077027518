import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from 'semantic-ui-react';
import { Grid } from '@mui/material';
import { Box, Tooltip } from '@material-ui/core/';
import { ToggleButtonGroup, ToggleButton, Pagination } from '@material-ui/lab/';
import { TextField, MenuItem } from '@mui/material';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { getCurrentParty, listWorkflows } from '../../api/api';
import StripedTable from '../../components/Table/StripedTable.jsx';
import ErrorMessage from '../../components/Error/Error';
import { useUserState, useUserDispatch, tabActive, workflowSelected } from '../../context/UserContext';

const Home = () => {
  const navigate = useNavigate();
  const user = useUserState();
  const userDispatch = useUserDispatch();
  const { getIdTokenClaims } = useAuth0();

  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(true);
  const [, setInitialRows] = useState();
  const [errorStatus, setErrorStatus] = useState();
  const [errorDetail, setErrorDetail] = useState();
  const [hasError, setHasError] = useState(false);
  const [firstRun, setFirstRun] = useState(true);

  // filter options
  const [producerIdArr, setProducerIdArr] = useState([]);
  const [commodityArr, setCommodityArr] = useState([]);
  const [tagsArr, setTagsArr] = useState([]);
  // table filter
  const [searchedProducerId, setSearchedProducerId] = useState();
  const [searchedCommodity, setSearchedCommodity] = useState();
  const [searchedTags, setSearchedTags] = useState([] || null);
  // pagination
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState();

  useEffect(() => {
    console.log('CONTEXT_API_UPDATED -> ', user);
  }, [user]);

  useEffect(() => {

    if (window.location.pathname === '/') {
      tabActive(userDispatch, 0);
    }

    setLoading(true);

    if (firstRun) {
      (async () => {
        try {
          const token = await getIdTokenClaims();
          document.cookie = `id_token=${token.__raw}`;  
  
        } catch (e) {
          console.error(e);
        }

        getCurrentParty()
        .then( (response) => {
          window.localStorage.setItem('current_party', JSON.stringify(response.data));
        }
        )
        .catch()
        .finally(() => {
          let cleanHrefUrl = window.location.href.split('//')[1];
          if (cleanHrefUrl.slice(-1) === '/')
            cleanHrefUrl = cleanHrefUrl.slice(0, -1);
  
          if (window.location.host !== cleanHrefUrl) {
            navigate(`/${cleanHrefUrl.substring(cleanHrefUrl.indexOf('/')+1)}`);
          } else {
            getFilters()
            getPayloads();
            setFirstRun(false)
          }
        })        
      })();
    } else {
      getPayloads();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, page, searchedProducerId, searchedCommodity, searchedTags]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage]);

  const getPayloads = () => {
    listWorkflows(
      itemsPerPage,
      page - 1,
      searchedProducerId,
      searchedCommodity,
      searchedTags
    )
      .then((result) => {
        setInitialRows(result.data.content);
        setRows(result.data.content);
        setNoOfPages(result.data.totalPages);
      })
      .catch((err) => {
        setHasError(true)
        setErrorStatus(err.response.data.status);
        setErrorDetail(err.response.data.detail);
      })
      .finally(() => {
        finishLoader();
      });
  };
  
  const getFilters = () => {
    listWorkflows(100000, '', '', '', '')
    .then((response) => {
      response.data.content.forEach((a, index) => {
        producerIdArr.push({
          key: index,
          value: String(a.workflowDefinition.producer),
          text: a.workflowDefinition.producer,
        });
        commodityArr.push({
          key: index,
          value: String(a.workflowDefinition.commodityType),
          text: a.workflowDefinition.commodityType,
        });
        tagsArr.push({
          key: index,
          value: String(a.workflowDefinition.tags),
          text: a.workflowDefinition.tags,
        });
      });

      const uniqueProducerIdObj = [
        ...new Map(producerIdArr.map((item) => [item.text, item])).values(),
      ];
      setProducerIdArr(uniqueProducerIdObj);

      const uniqueCommodityArrObj = [
        ...new Map(commodityArr.map((item) => [item.text, item])).values(),
      ];
      setCommodityArr(uniqueCommodityArrObj);

      let tagArr = [];
      tagsArr?.forEach((b) => {
        b.text?.forEach((c, i) => {
          tagArr.push(c);
        });
      });
      const uniqueTagsArr = [...new Set(tagArr)];

      let tagArrOptions = [];
      uniqueTagsArr.forEach((c, i) => {
        tagArrOptions.push({ key: i, value: c, text: c });
      });
      setTagsArr(tagArrOptions);

    })
    .catch((err) => {
      setHasError(true)
      setErrorStatus(err.response.status);
      setErrorDetail(err.response.data.detail);
    });
  }

  // export payload list
  const workflowsCSV = (e) => {
    e.preventDefault()
    let headers = ['Workflow RIID, Registry Operator, Active Automated Payloads, Active Manual Payloads, Total Payloads, Commodity Type, Primary Data Refiners, Secondary Data Refiners, Step Types']
    
    listWorkflows(100000, '', '', '', '')
      .then((response) => {
        let workflowsCsv = response.data.content.reduce((acc, row) => {
          const convertArrayToObject = (array, key) => {
            const initialValue = {};
            return array.reduce((obj, item) => {
              return {
                ...obj,
                [item[key]]: item,
              };
            }, initialValue);
          };
    
          acc.push([
            row.workflowDefinition.riid,
            row.workflowDefinition.registryOperator,
            row.workflowDefinition.title,
            row.activeAutomatedPayloads,
            row.activeManualPayloads,
            row.totalPayloads,
            row.workflowDefinition.producer,
            row.workflowDefinition.commodityType,
            row.workflowDefinition.primaryDataRefiners,
            row.workflowDefinition.secondaryDataRefiners,
            row.workflowDefinition.tags,
            Object.keys(convertArrayToObject(row.workflowDefinition.steps, '@type')),
          ])
    
        return acc
    
        }, [])
        downloadFile({
          data: [...headers, ...workflowsCsv].join('\n'),
          fileName: 'Workflows',
          fileType: 'text/csv',
        })
      }) 
  }

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })

    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  const handleItemsPerPage = (event, newAmount) => {
    setItemsPerPage(newAmount);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const payloadTab = (riid) => {
    workflowSelected(userDispatch, riid);
    navigate(`workflows/${riid}`);
  };

  const finishLoader = () => {
    setLoading(false);
  };

  const setTag = (newTag) => {
    let tags = [];
    tags.push(newTag);
    setSearchedTags(tags);
  };

  return (
    <div className="home">
      {rows?.length === undefined && !hasError ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <>
          {hasError ? (
            <ErrorMessage statusCode={errorStatus} detail={errorDetail}></ErrorMessage>
          ) : (
            <Box
                sx={{
                  width: '100%',
                  border: '1px solid lightgrey',
                  borderRadius: '4px',
                  padding: '20px',
                  marginBottom: '20px',
                  display: 'block',
                }}
              >
                <div className='Home'>
                  <section className='filter-section'>
                    <Autocomplete
                      id='producerId'
                      renderInput={(params) => (
                        <TextField {...params} label='Search by Producer Id' />
                      )}
                      getOptionLabel={(option) => option.text}
                      defaultValue={searchedProducerId}
                      onChange={(valueSelected) => {
                        setSearchedProducerId(valueSelected.target.innerText);
                      }}
                      options={producerIdArr}
                    />
                    <TextField
                      select
                      label='Commodity'
                      placeholder='Commodity'
                      value={searchedCommodity}
                      onChange={(valueSelected) => {
                        setSearchedCommodity(valueSelected.target.value);
                      }}
                      >
                        <MenuItem key={"all"} value="">All</MenuItem>
                        {commodityArr.map(el =>
                          <MenuItem key={el.key} value={el.value}>{el.text}</MenuItem>
                        )}
                    </TextField>
                    <Autocomplete
                      id='tags'
                      multiple
                      options={tagsArr}
                      renderInput={(params) => (
                        <TextField {...params} label='Search by Tag' />
                      )}
                      getOptionLabel={(option) => option.text}
                      defaulvalue={searchedTags}
                      onChange={(_event, newTag) => {
                        setTag(newTag);
                      }}
                    />
                  </section>
                  {rows?.length !== undefined && !loading ? (
                    <div className="content">
                      <StripedTable
                        aria-label='simple table'
                        rowsClickable
                        headings={[
                          '',
                          '',
                          '',
                          '',
                          'Title',
                          'RIID',
                          'Active Payloads',
                          'Total Payloads',
                          'Tags',
                        ]}
                        rows={rows?.map((data, index) => {
                          if (data) {
                            return {
                              elements: [
                                <p index={index}></p>,
                                <p>{data.workflowDefinition.title}</p>,
                                <p>{data.workflowDefinition.commodityType}</p>,
                                <p>{data.workflowDefinition.tags}</p>,
                                <p>{data.workflowDefinition.title}</p>,
                                <p>{data.workflowDefinition.riid}</p>,
                                <p>{data.activePayloads}</p>,
                                <p>{data.totalPayloads}</p>,
                                <p>
                                  {data.workflowDefinition.tags.map(
                                    (tag, index) => {
                                      return data.workflowDefinition.tags
                                        ?.length ? (
                                        <span key={index}>{tag}</span>
                                      ) : (
                                        <>
                                          <span key={index}>-</span>
                                        </>
                                      );
                                    }
                                  )}
                                </p>,
                              ],
                              onClick: () => {
                                payloadTab(data.workflowDefinition.riid);
                              },
                            };
                          } else {
                            return {
                              elements: [],
                            };
                          }
                        })}
                      />
                      
                      <Grid container spacing={2}>
                        <Grid item md={4}/>
                          <Grid item md={4}>
                            <Box component='div'>
                              <Pagination
                                count={noOfPages}
                                page={page}
                                onChange={handleChangePage}
                                defaultPage={0}
                                color='primary'
                                size='small'
                              />
                            </Box>
                          </Grid>
                          {rows?.length > 0 &&
                            <Grid item md={4} p={2}>
                              <Box component='div' justifyContent={'right'} className='perpage'>
                                <Tooltip
                                  placement={'right'}
                                  arrow
                                  title={<span>View per page</span>}
                                >
                                  <ToggleButtonGroup
                                    value={itemsPerPage}
                                    exclusive
                                    onChange={handleItemsPerPage}
                                    aria-label='text alignment'
                                    size='small'
                                  >
                                    <ToggleButton value={5} aria-label='left aligned'>
                                      5
                                    </ToggleButton>
                                    <ToggleButton value={50} aria-label='centered'>
                                      50
                                    </ToggleButton>
                                    <ToggleButton value={100} aria-label='justified'>
                                      100
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                </Tooltip>
                              </Box>
                            </Grid>
                          }
                        </Grid>
                        <section className='export-btn-wrapper'>
                          {rows?.length ? (
                            <div className="export">
                              <Tooltip placement={'top'} arrow title='Export CSV'>
                                <button onClick={workflowsCSV} className='sm-btn'>
                                  csv
                                </button>
                              </Tooltip>
                            </div>

                          ) : (
                            ''
                          )}
                        </section>
                      </div>
                  ) : (
                    <>
                      {rows?.length > 0 ? (
                        <div className='empty-table'>
                          <Loader active indeterminate size='small' />
                        </div>
                      ) : (
                        <StripedTable rows={[]}></StripedTable>
                      )}
                    </>
                  )}
                </div>
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default Home;