import axios from 'axios';
import jwt_decode from "jwt-decode";
import { beURL} from '../constants/auth';

let baseUrl = beURL;

const getTokenFromCookies = () => {
  let token = document.cookie.match('(^|;)\\s*id_token\\s*=\\s*([^;]+)')?.pop() || '';

  const tokenDecoded = jwt_decode(token);
  const now = Date.now().toString().substring(0,10)

  if (tokenDecoded.exp < now)
    window.location.href = "/";
  else
    return token;
};

export const listWorkflows = (size, page, producer, commodity, tag) => {
  let url = `${baseUrl}/api/v1/workflow-definitions?size=${size}&page=${page}`;

  if (producer) {
    url = url + `&producer=${String(producer)}`;
  }

  if (commodity) {
    url = url + `&commodityType=${commodity}`;
  }

  if (tag && tag.length < 5) {
    tag[0]?.forEach((t) => {
      url = url + `&tags=${t.value}`;
    });
  }

  let response = axios.get(url, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};

export const listWorkflow = (riid) => {
  let response = axios.get(`${baseUrl}/api/v1/workflow-definitions/${riid}`, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};

export const listPayloads = (riid) => {
  let response = axios.get(
    `${baseUrl}/api/v1/workflow-definitions/${riid}/payloads`,
    {
      headers: {
        Authorization: `Bearer ${getTokenFromCookies()}`,
        Accept: `*/*`,
      },
      maxRedirects: 0,
    }
  );

  return response;
};

export const restatePayloadEvent = (payloadId) => {
  let response = axios.post(`${baseUrl}/api/v1/payloads/${payloadId}/restatement-events`, null, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};

export const addNotableEvent = (payloadId, data) => {
  let response = axios.post(`${baseUrl}/api/v1/payloads/${payloadId}/notable-events`, data, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};

export const addGeneralManualStep = (payloadId, data) => {
  let response = axios.post(`${baseUrl}/api/v1/payloads/${payloadId}/events`, data, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};

export const listLedgerParties = (id) => {
  let response = axios.get(`${baseUrl}/api/v1/parties?size=100000&isCertifyingParty=true`, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};

export const getCurrentParty = (id) => {
  let response = axios.get(`${baseUrl}/api/v1/current-party`, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};

export const listProductionEntities = (size, page, type, location) => {
  let url = `${baseUrl}/api/v1/production-entities?size=${size}&page=${page}`;

  if (type) {
    url = url + `&productionEntityType=${String(type)}`;
  }

  if (location) {
    url = url + `&location=${location}`;
  }

  let response = axios.get(url, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};


export const listProductionEntitiesForParty = (certifyingBodyParty, certificateHolderParty) => {
  let url = `${baseUrl}/api/v1/production-entities?certifyingBodyPartyId=${certifyingBodyParty}&certificateHolderPartyId=${certificateHolderParty}&size=10000`;

  let response = axios.get(url, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};

export const listProductionEntity = (id) => {
  let response = axios.get(`${baseUrl}/api/v1/production-entities/${id}`, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};

export const addProductionEntity = (data) => {
  let response = axios.post(`${baseUrl}/api/v1/production-entities/`, data, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};

export const editProductionEntity = (id, data) => {
  let response = axios.put(`${baseUrl}/api/v1/production-entities/${id}`, data, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};

export const deleteProductionEntity = (id) => {
  let response = axios.delete(`${baseUrl}/api/v1/production-entities/${id}`, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};

export const listMasterCertificates = (size, page, type, searchActivationDate, searchExpirationDate, searchFacilityId, searchCertificateHolderName) => {
  let url = `${baseUrl}/api/v1/master-certificates?size=${size}&page=${page}`;

  if (type) {
    url = url + `&certificateType=${String(type)}`;
  }
  if (searchActivationDate) {
    url = url + `&activationDate=${searchActivationDate}T00:00:00Z`;
  }
  if (searchExpirationDate) {
    url = url + `&expirationDate=${searchExpirationDate}T23:59:59Z`;
  }
  if (searchFacilityId) {
    url = url + `&facilityId=${searchFacilityId}`;
  }
  if (searchCertificateHolderName) {
    url = url + `&certificateHolderPartyName=${searchCertificateHolderName}`;
  }

  let response = axios.get(url, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};

export const listMasterCertificate = (id) => {
  let response = axios.get(`${baseUrl}/api/v1/master-certificates/${id}`, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};

export const addMasterCertificate = (data) => {
  let response = axios.post(`${baseUrl}/api/v1/master-certificates/`, data, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};

export const editMasterCertificate = (id, data) => {
  let response = axios.put(`${baseUrl}/api/v1/master-certificates/${id}`, data, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};


export const issueMasterCertificate = (data) => {
  let response = axios.post(`${baseUrl}/api/v1/issued-certificates/`, data, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};

export const deleteMasterCertificate = (id) => {
  let response = axios.delete(`${baseUrl}/api/v1/master-certificates/${id}`, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};


export const listIssuedCertificates = (size, page, searchActivationDate, searchExpirationDate) => {
  let url = `${baseUrl}/api/v1/issued-certificates?size=${size}&page=${page}`;

  if (searchActivationDate) {
    url = url + `&activationDate=${searchActivationDate}T00:00:00Z`;
  }
  if (searchExpirationDate) {
    url = url + `&expirationDate=${searchExpirationDate}T23:59:59Z`;
  }

  let response = axios.get(url, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};

export const listIssuedCertificate = (id) => {
  let response = axios.get(`${baseUrl}/api/v1/issued-certificates/${id}`, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};

export const listEvents = (payload) => {
  let response = axios.get(`${baseUrl}/api/v1/payloads/${payload}`, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};

export const getNextStep = (payload) => {
  let response = axios.get(`${baseUrl}/api/v1/payloads/${payload}/nextStep`, {
    headers: {
      Authorization: `Bearer ${getTokenFromCookies()}`,
      Accept: `*/*`,
    },
    maxRedirects: 0,
  });

  return response;
};
