/* eslint-disable no-unused-vars */
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import { UserProvider } from "./context/UserContext";
import Home from "./pages/Home/Home.jsx";
import Login from "./pages/Login/Login.jsx";
import Workflows from "./pages/Workflows/Workflows";
import Payloads from "./pages/Payloads/Payloads";
import ProductionEntities from "./pages/ProductionEntities/ProductionEntities";
import ProductionEntitiesDetails from "./pages/ProductionEntities/Details/ProductionEntitiesDetails";
import AddProductionEntity from "./pages/ProductionEntities/Add/AddProductionEntity";
import MasterCertificates from "./pages/MasterCertificates/MasterCertificates";
import MasterCertificatesDetails from "./pages/MasterCertificates/Details/MasterCertificatesDetails";
import AddMasterCertificate from "./pages/MasterCertificates/Add/AddMasterCertificate";
import IssueMasterCertificate from "./pages/MasterCertificates/Issue/IssueMasterCertificate";
import IssuedCertificates from "./pages/IssuedCertificates/IssuedCertificates";
import IssuedCertificatesDetails from "./pages/IssuedCertificates/Details/IssuedCertificatesDetails";
import { ProtectedRoute } from './auth/protectedRoute';
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#29B57D"
    },
  }
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <UserProvider>
          <Routes>
            <Route exact path="/" element={<ProtectedRoute component={Home} />}/>
            <Route exact path="/workflows/:workflowId" element={<ProtectedRoute component={Workflows} />}/>
            <Route exact path="/payloads/:payloadId" element={<ProtectedRoute component={Payloads} />}/>
            <Route exact path="/production-entities/" element={<ProtectedRoute component={ProductionEntities} />}/>
            <Route exact path="/production-entities/:id" element={<ProtectedRoute component={ProductionEntitiesDetails} />}/>
            <Route exact path="/production-entities/add" element={<ProtectedRoute component={AddProductionEntity} />}/>
            <Route exact path="/production-entities/:id/edit" element={<ProtectedRoute component={AddProductionEntity} />}/>
            <Route exact path="/master-certificates/" element={<ProtectedRoute component={MasterCertificates} />}/>
            <Route exact path="/master-certificates/:id" element={<ProtectedRoute component={MasterCertificatesDetails} />}/>
            <Route exact path="/master-certificates/add" element={<ProtectedRoute component={AddMasterCertificate} />}/>
            <Route exact path="/master-certificates/:id/edit" element={<ProtectedRoute component={AddMasterCertificate} />}/>
            <Route exact path="/master-certificates/:id/issue" element={<ProtectedRoute component={IssueMasterCertificate} />}/>
            <Route exact path="/issued-certificates/" element={<ProtectedRoute component={IssuedCertificates} />}/>
            <Route exact path="/issued-certificates/:id" element={<ProtectedRoute component={IssuedCertificatesDetails} />}/>
            <Route exact path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/" replace />}/>
          </Routes>
        </UserProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
