import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Loader } from 'semantic-ui-react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Grid } from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, Tooltip } from '@material-ui/core/';
import TextField from '@mui/material/TextField';
import { ToggleButtonGroup, ToggleButton, Pagination } from '@material-ui/lab/';

import StripedTable from '../../components/Table/StripedTable.jsx';
import ErrorMessage from '../../components/Error/Error';
import { listIssuedCertificates } from '../../api/api';
import { useUserDispatch, tabActive } from '../../context/UserContext';
import { dateFormatter, escapeEmptyDetail } from '../../utils/utils.jsx';
import IssuedCertificateToPDF from '../../pdfs/IssuedCertificate';

const IssuedCertificates = () => {
  const navigate = useNavigate();
  const userDispatch = useUserDispatch();

  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(true);
  const [, setInitialRows] = useState();
  const [errorStatus, setErrorStatus] = useState();
  const [errorDetail, setErrorDetail] = useState();
  const [hasError, setHasError] = useState(false);

  // filter options
  const [typeArr, setTypeArr] = useState([]);
  const [statusArr, setStatusArr] = useState([]);
  // table filter
  const [searchActivationDate, setSearchActivationDate] = useState();
  const [searchExpirationDate, setSearchExpirationDate] = useState();
  // pagination
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState();

  useEffect(() => {
    if (window.location.pathname === '/issued-certificates') {
      tabActive(userDispatch, 3);
    }

    getIssuedCertificates();
    getFilters();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, page, searchActivationDate, searchExpirationDate]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage]);

  const getFilters = () => {
    
    setLoading(true);

    listIssuedCertificates('', '', '', '', '')
    .then((response) => {
      response.data.content.forEach((a, index) => {
        typeArr.push({
          key: index,
          value: String(a.certificateType),
          text: a.certificateType,
        });
        statusArr.push({
          key: index,
          value: String(a.status),
          text: a.status,
        });
      });

      const uniqueTypeObj = [
        ...new Map(typeArr.map((item) => [item.text, item])).values(),
      ];
      setTypeArr(uniqueTypeObj);

      const uniqueStatusArrObj = [
        ...new Map(statusArr.map((item) => [item.text, item])).values(),
      ];
      setStatusArr(uniqueStatusArrObj);

    })
    .catch((err) => {
      setHasError(true);
      setErrorStatus(err.response.status);
      setErrorDetail(err.response.data.detail);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  const getIssuedCertificates = () => {

    setLoading(true);
    
    listIssuedCertificates(
      itemsPerPage,
      page - 1,
      searchActivationDate,
      searchExpirationDate
    )
      .then((result) => {
        setInitialRows(result.data.content);
        setRows(result.data.content);
        setNoOfPages(result.data.totalPages);
      })
      .catch((err) => {
        setHasError(true);
        setErrorStatus(err.response.data.status);
        setErrorDetail(err.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleItemsPerPage = (event, newAmount) => {
    setItemsPerPage(newAmount);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <>
      {loading || (rows?.length === undefined && !hasError) ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <>
          {hasError ? (
            <ErrorMessage statusCode={errorStatus} detail={errorDetail}></ErrorMessage>
          ) : (
            <div className='Home'>
              <section className='filter-section'>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    format='DD-MM-YYYY'
                    label='Activation Date'
                    value={
                      searchActivationDate ? searchActivationDate : ''
                    }
                    onChange={(newValue) => {
                      if(moment(moment(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid())
                        setSearchActivationDate(moment(newValue).format('YYYY-MM-DD'))
                        else
                          setSearchActivationDate(null)
                    }}
                    renderInput={(params) => <TextField {...params} onKeyDown={(e) => e.preventDefault()} />}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    format='DD-MM-YYYY'
                    label='Expiration Date'
                    value={
                      searchExpirationDate ? searchExpirationDate : ''
                    }
                    onChange={(newValue) => {
                      if(moment(moment(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid())
                        setSearchExpirationDate(moment(newValue).format('YYYY-MM-DD'))
                      else
                        setSearchExpirationDate(null)
                    }}
                    renderInput={(params) => <TextField {...params} onKeyDown={(e) => e.preventDefault()} />}
                  />
                </LocalizationProvider>
              </section>
              <section className='content'>
                {rows?.length !== undefined && !loading ? (
                  <>
                    <StripedTable
                      aria-label='simple table'
                      rowsClickable
                      headings={[
                        '',
                        '',
                        '',
                        '',
                        'Id',
                        'Master Certificate Id',
                        'External Payload Id',
                        'Activation Date',
                        'Expiration Date',
                        ''
                      ]}
                      rows={rows?.map((data, index) => {
                        if (data) {
                          return {
                            elements: [
                              <p index={index}></p>,
                              <p index={index}></p>,
                              <p index={index}></p>,
                              <p index={index}></p>,
                              <p>{escapeEmptyDetail(data.issuedCertificateId)}</p>,
                              <p>{escapeEmptyDetail(data.masterCertificateId)}</p>,
                              <p>{escapeEmptyDetail(data.externalPayloadId)}</p>,
                              <p>{dateFormatter(data.activationDate)}</p>,
                              <p>{dateFormatter(data.expirationDate)}</p>,
                              <section className='export-btn-wrapper'>
                                <PDFDownloadLink
                                  document={IssuedCertificateToPDF(data)}
                                  fileName={data.certificateName}
                                >
                                  <Tooltip placement={'top'} arrow title='Export PDF'>
                                    <button className='sm-btn' onClick={(e) => e.stopPropagation()}> pdf </button>
                                  </Tooltip>
                                </PDFDownloadLink>
                              </section>
                            ],
                            onClick: () => {
                              navigate(`/issued-certificates/${data.issuedCertificateId}`);
                            },
                          };
                        } else {
                          return {
                            elements: [],
                          };
                        }
                      })}
                    ></StripedTable>
                    <Grid container spacing={2}>
                      <Grid item md={4}/>
                      <Grid item md={4}>
                        <Box component='div'>
                          <Pagination
                            count={noOfPages}
                            page={page}
                            onChange={handleChangePage}
                            defaultPage={0}
                            color='primary'
                            size='small'
                          />
                        </Box>
                      </Grid>
                      {rows?.length > 0 &&
                        <Grid item md={4} p={2}>
                          <Box component='div' justifyContent={'right'} className='perpage'>
                            <Tooltip
                              placement={'right'}
                              arrow
                              title={<span>View per page</span>}
                            >
                              <ToggleButtonGroup
                                value={itemsPerPage}
                                exclusive
                                onChange={handleItemsPerPage}
                                aria-label='text alignment'
                                size='small'
                              >
                                <ToggleButton value={5} aria-label='left aligned'>
                                  5
                                </ToggleButton>
                                <ToggleButton value={50} aria-label='centered'>
                                  50
                                </ToggleButton>
                                <ToggleButton value={100} aria-label='justified'>
                                  100
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Tooltip>
                          </Box>
                        </Grid>
                      }
                    </Grid>
                  </>
                ) : (
                  <>
                    {rows?.length > 0 ? (
                      <div className='empty-table'>
                        <Loader active indeterminate size='small' />
                      </div>
                    ) : (
                      <StripedTable rows={[]}></StripedTable>
                    )}
                  </>
                )}
              </section>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default IssuedCertificates;