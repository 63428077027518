import { Grid } from "semantic-ui-react";
import { readableStepName, escapeEmptyDetail } from '../../utils/utils';

export const IDGeneratorStepModel = (step) => {
  return (
    <>
      <p className='details-title'>
        <b>
          { readableStepName(step['@type']) }
        </b>
      </p>
      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={5}>
          <p className="detail-title">Title </p>
          <p className="detail-text">{escapeEmptyDetail(step.title)}</p>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={5}>
          <p className="detail-title">Description </p>
          <p className="detail-text">{escapeEmptyDetail(step.description)}</p>
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={5}>
          <p className="detail-title">Party Id </p>
          <p className="detail-text">{escapeEmptyDetail(step.partyId)}</p>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={5}>
          <p className="detail-title">Party Name </p>
          <p className="detail-text">{escapeEmptyDetail(step.partyName)}</p>
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={5}>
          <p className="detail-title">Process Name </p>
          <p className="detail-text">{escapeEmptyDetail(step.processName)}</p>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={5}>
          <p className="detail-title">Process Version </p>
          <p className="detail-text">{escapeEmptyDetail(step.processVersion)}</p>
        </Grid.Column>
      </Grid>
    </>
  );
};
