import React from "react";
import { useNavigate } from 'react-router-dom';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  useUserState,
  useUserDispatch,
  tabActive
} from "../../context/UserContext";


const TabMenu = () => {
  const user = useUserState();
  const navigate = useNavigate();
  const userDispatch = useUserDispatch();

  const tabRouting = (e, value) => {
    tabActive(userDispatch, value);

    if (value === 0) {
      navigate('/')
    } else if (value === 1) {
      navigate('/production-entities')
    } else if (value === 2) {
      navigate('/master-certificates')
    } else if (value === 3) {
      navigate('/issued-certificates')
    }
  }

  return (
    <Tabs
      value={user.tabActive}
      variant="fullWidth"
      indicatorColor="primary"
      textColor="primary"
      aria-label="icon tabs example"
      onChange={tabRouting}
    >
      <Tab label="Workflows" />
      {/* <Tab label="Workflow Definition" />
      <Tab label="Workflow Progress" /> */}
      <Tab label="Production Entities" />
      <Tab label="Master Certificates" />
      <Tab label="Issued Certificates" />
    </Tabs>
  );
};

export default TabMenu;
